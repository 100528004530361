import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentEmission from "./TopComponentEmission";
import LocationWiseEmission from "./LocationWiseEmission";
import GHGEmissions from "./GHGEmissions";
import EmissionAcrossLocation from "./EmissionAcrossLocation";
import ProductWiseEmission from "./ProductWiseEmission";
import ScopeTwo from "./ScopeTwo";
import ComparisonScope from "./ComparisonScope";
import ComparisonAcrossYear from "./ComparisonAcrossYear";
import TotalEmissionGenerated from "./TotalCommissionGranted";
import TotalGHGEmissionPercentage from "./TotalGHGEmission";
import ScopeMultiLocComparison from "./ScopeComMulLoc";
import TotalCarbonEmission from "./TotalCarbonEmission";
import EmissionIntensityOverTime from "./EmissionIIntensityOverTime";
import CarbonEmissionByScope from "./CarbonEmissionByScope";
import LineChartWithOptions from "./LineChartEmission";
import MixedChart from "./MixedChart";
import Speedometer from "./Speedometer";
import DifferentColumnCharts from "../Company Sub Admin/Pages/home/DifferentColumnCharts";
import TotalProductWise from "../Company Sub Admin/Pages/home/TotalProductWise";
import TotalEmissionGeneratedSingle from "./TotalEmissionGenerated";
import ScopeComSingleLoc from "./ScopeComSingleLoc";
import TotalCarbonGeneratedSingle from "./TotalCarbonSingleLoc";
import CarbonEmissionByScopeSingle from "./CarbonEmissionByScopeSingle";
import ProductWiseScopeTwo from "./ProductWiseScopeTwo";
import IntensityOverTime from "./IntensityOverTime";
import ProductWiseEmissionSingle from "./ProductWiseEmissionSingle";
import COTWOReduction from "./COTWOReduction";

const Emission = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
  frameworkValue,
  sectorQuestionAnswerDataForGraph,
}) => {
  const location = 2;
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const [companyFramework, setCompanyFramework] = useState([]);
  
  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };
  
  // Check if sectorQuestionAnswerDataForGraph is defined and has at least one element
  const scopeOne = sectorQuestionAnswerDataForGraph?.[0]?.answer
    ? Number(JSON.parse(sectorQuestionAnswerDataForGraph[0]["answer"])?.[0]?.[1]) || 0
    : 0;
  
  const scopeTwo = sectorQuestionAnswerDataForGraph?.[0]?.answer
    ? Number(JSON.parse(sectorQuestionAnswerDataForGraph[0]["answer"])?.[1]?.[1]) || 0
    : 0;
  
  const totalScope = scopeOne + scopeTwo;
  
  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
  
    if (isSuccess) {
      const data = {
        "Total Emission": {
          number: `${totalScope} tCO2e`,
          questionId: [],
        },
        "Scope 1 Emission": {
          number: `${scopeOne} tCO2e`,
          questionId: [],
        },
        "Scope 2 Emission": {
          number: `${scopeTwo} tCO2e`,
          questionId: [],
        },
        message: "Good Evening, Sunil Kumar",
      };
      setLastWeekAcitivities(data);
    }
  };
  
  useEffect(() => {
    lastWeekActivity();
  }, []);
  
  useEffect(() => {
    if (frameworkValue?.length) {
      const frameworkId = frameworkValue.map((value) => value?.id).filter(id => id !== undefined);
      setCompanyFramework(frameworkId);
    }
  }, [frameworkValue]);


  return (
    <div className="progress-container">
      <div className="topcompo" style={{height:"16vh"}}>
        {companyFramework &&
        companyFramework.length &&
        companyFramework.includes(1)&& lastWeekAcitivities && (
          <TopComponentEmission
            lastWeekAcitivities={lastWeekAcitivities}
            icons={icons}
          />
        )}
      </div>
  
      {(
        <div className="d-flex flex-column flex-space-between">
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <TotalEmissionGenerated />
            </div>
            <div className="secondhalfprogress">
              <TotalGHGEmissionPercentage />
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <ScopeMultiLocComparison />
            </div>
            <div className="secondhalfprogress">
              <TotalCarbonEmission />
            </div>
          </div> */}
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <EmissionIntensityOverTime />
            </div>
            <div className="secondhalfprogress">
              <CarbonEmissionByScope />
            </div>
          </div> */}
          {companyFramework &&
          companyFramework.length &&
          companyFramework.includes(1) ? (
            <div className="d-flex flex-column flex-space-between">
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "22vh", marginBottom: "3%" }}
            >
              <TotalEmissionGeneratedSingle consumption={scopeOne} notGenerated={scopeTwo} />
            </div>
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "30vh", marginBottom: "3%" }}
            >
              {/* <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
                <ScopeComSingleLoc title={"Scope 1 and Scope 2 Comparison"} />
              </div> */}
              {/* <div className="secondhalfprogress" style={{ width: "50%" }}> */}
                {/* <div style={{ height: "55%" , marginBottom:"2%"  }}>
                  <TotalCarbonGeneratedSingle />
                </div> */}
                <div style={{ height: "100%", width:"100%" }}>
                  <CarbonEmissionByScopeSingle
                    notGenerated={scopeOne}
                    maxConsumption={scopeOne+scopeTwo}
                    consumption={scopeTwo}
                  />
                </div>
              {/* </div> */}
            </div>
            {/* <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "70vh", marginBottom: "3%" }}
            >
              <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
                <div style={{ height: "55%" ,  marginBottom:"2%"}}>
                  <ProductWiseScopeTwo
                    notGenerated={15}
                    maxConsumption={40}
                    consumption={10}
                  />
                </div>
                <div style={{ height:"48%" , }}>
                  <IntensityOverTime />
                </div>
              </div>
              <div className="secondhalfprogress" style={{ width: "50%" }}>
                <ProductWiseEmissionSingle />
              </div>
            </div> */}
            {/* <div className="d-flex flex-row flex-space-between" style={{height:"30vh"}}>
              <COTWOReduction/>
  
            </div> */}
          </div>
          ) : (
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "70vh", marginBottom: "3%" }}
            >
              <div className="firsthalfprogressenergy">
                <LineChartWithOptions
                  locationOption={locationOption}
                  timePeriods={timePeriods}
                  financialYearId={financialYearId}
                  graphData={graphData}
                />
              </div>
              <div className="secondhalfprogress">
                <ScopeMultiLocComparison
                  locationOption={locationOption}
                  timePeriods={timePeriods}
                  financialYearId={financialYearId}
                  graphData={graphData}
                />
              </div>
            </div>
          )}
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <LineChartWithOptions locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData} />
            </div>
            <div className="secondhalfprogress">
              <ScopeMultiLocComparison   locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData}/>
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <MixedChart />
            </div>
            <div className="secondhalfprogress">
              <Speedometer />
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Emission;
