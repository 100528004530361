import React from "react";
import Table from "react-bootstrap/Table";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import { useEffect, useState } from "react";
import "./teamworkload.css";
import "../ProgressBySector/sectorprogress.css";
import { NavLink } from "react-router-dom";
import no from "../../img/no.png";

const TeamWorkLoad = ({
  fromDate,
  toDate,
  financialYearId,
  handleTeamWorkloadData,
  user,
  framework,
  timePeriods,
  locationOption,
}) => {
  const [teamWorkloadData, setTeamWorkloadData] = useState([]);
  const [activeLegend, setActiveLegend] = useState(() => {
    const baseLegend = ["accepted", "not-responded"];
    const isUser = user === "user"; // or pass it as a prop if needed

    return isUser
      ? [...baseLegend, "rejected", "assigned", "answered"]
      : baseLegend;
  });

  const teamWorkloadProgess = async () => {
    const frameworkIds = framework.map((item) => item.value);
    const locationIdsIds =
      locationOption && locationOption.map((item) => item.id);
    const periods = Object.values(timePeriods);
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}teamWorkloadProgess`,
      {},
      {
        fromDate: fromDate,
        toDate: toDate,
        financialYearId,
        frameworkIds,
        locationIdsIds,
        periods,
      },
      "GET"
    );
    if (isSuccess) {
      setTeamWorkloadData(data?.data?.teamWorkloadResults);

      handleTeamWorkloadData(data?.data?.teamWorkloadResults, user);
    }
  };

  useEffect(() => {
    if (
      framework &&
      framework.length &&
      financialYearId &&
      locationOption &&
      locationOption.length
    ) {
      teamWorkloadProgess();
    }
  }, [framework, locationOption, timePeriods, financialYearId]);

  const handleLegendClick = (legendType) => {
    if (activeLegend.includes(legendType)) {
      setActiveLegend(activeLegend.filter((item) => item !== legendType));
    } else {
      setActiveLegend([...activeLegend, legendType]);
    }
  };

  const renderBars = (item) => {
    const isUser = user === "user";
    const bars = [];

    // Handle Accepted for Audit
    if (
      user === "audit" &&
      Number(item?.accepted) !== 0 &&
      activeLegend.includes("accepted")
    ) {
      bars.push(
        <NavLink
          key="accepted"
          onClick={() => {
            localStorage.setItem(
              "reportingQuestion",
              JSON.stringify(item.acceptedQuestionIds)
            );
          }}
          to={{
            pathname: "audit-history",
            state: {
              reportingQuestion: item.acceptedQuestionIds,
              userId: item?.userId,
            },
          }}
          style={{
            display: "block",
            width: `${Number(item?.percentageAccepted)}%`,
            padding: "0px",
            margin: "0px",
          }}
        >
          <div
            className="bar accepted"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              width: "100%",
            }}
          >
            <span className="bar-label">{`${Number(item?.accepted)}`}</span>
          </div>
        </NavLink>
      );
    }

    if (
      user === "audit" &&
      Number(item?.accepted) !== 0 &&
      activeLegend.includes("rejected")
    ) {
      bars.push(
        <NavLink
          key="rejected"
          onClick={() => {
            localStorage.setItem(
              "reportingQuestion",
              JSON.stringify(item.rejectedQuestionIds)
            );
          }}
          to={{
            pathname: "audit-history",
            state: {
              reportingQuestion: item.rejectedQuestionIds,
              userId: item?.userId,
            },
          }}
          style={{
            display: "block",
            width: `${Number(item?.percentageRejected)}%`,
            padding: "0px",
            margin: "0px",
          }}
        >
          <div
            className="bar rejected"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              width: "100%",
            }}
          >
            <span className="bar-label">{`${Number(item?.rejected)}`}</span>
          </div>
        </NavLink>
      );
    }

    // Handle Answered for User
    if (
      isUser &&
      Number(item?.answered) !== 0 &&
      activeLegend.includes("answered")
    ) {
      bars.push(
        <NavLink
          key="answered"
          onClick={() => {
            localStorage.setItem(
              "reportingQuestion",
              JSON.stringify(item.answeredQuestionIds)
            );
          }}
          to={{
            pathname: "reporting-modules/all-module",
            state: {
              reportingQuestion: item.answeredQuestionIds,
            },
          }}
          style={{
            display: "block",
            width: `${
              (Number(item?.answered) /
                Number(
                  Number(item?.answered) +
                    Number(item?.totalAssignedQuestionForAnswered) +
                    Number(item?.finalAssignQuesionAccpted) +
                    Number(item?.finalAssignQuesionRejected) +
                    item.answerNotResponded
                )) *
              100
            }%`,
            padding: "0px",
            margin: "0px",
          }}
        >
          <div
            className="bar answered"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              width: "100%",
            }}
          >
            <span className="bar-label">{`${Number(item?.answered)}`}</span>
          </div>
        </NavLink>
      );
    }

    if (
      isUser &&
      Number(item?.answered) !== 0 &&
      activeLegend.includes("assigned")
    ) {
      bars.push(
        <NavLink
          key="assigned"
          onClick={() => {
            localStorage.setItem(
              "reportingQuestion",
              JSON.stringify(item.finalAssgnedQuestionIds)
            );
          }}
          to={{
            pathname: "reporting-modules/all-module",
            state: {
              reportingQuestion: item.finalAssgnedQuestionIds,
            },
          }}
          style={{
            display: "block",
            width: `${
              (Number(item?.totalAssignedQuestionForAnswered) /
                Number(
                  Number(item?.answered) +
                    Number(item?.totalAssignedQuestionForAnswered) +
                    Number(item?.finalAssignQuesionAccpted) +
                    Number(item?.finalAssignQuesionRejected) +
                    item.answerNotResponded
                )) *
              100
            }%`,
            padding: "0px",
            margin: "0px",
          }}
        >
          <div
            className="bar assigned"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              width: "100%",
            }}
          >
            <span className="bar-label">{`${Number(
              item?.totalAssignedQuestionForAnswered
            )}`}</span>
          </div>
        </NavLink>
      );
    }

    if (
      isUser &&
      Number(item?.finalAssignQuesionAccpted) !== 0 &&
      activeLegend.includes("accepted")
    ) {
      bars.push(
        <NavLink
          key="accepted"
          onClick={() => {
            localStorage.setItem(
              "reportingQuestion",
              JSON.stringify(item.finalAssignQuesionAccptedIds)
            );
          }}
          to={{
            pathname: isUser
            ? "reporting-modules/all-module"
            : "/audit-listing/all-module",
            state: {
              reportingQuestion: item.finalAssignQuesionAccptedIds,
            },
          }}
          style={{
            display: "block",
            width: `${
              (Number(item?.finalAssignQuesionAccpted) /
                Number(
                  Number(item?.answered) +
                    Number(item?.totalAssignedQuestionForAnswered) +
                    Number(item?.finalAssignQuesionAccpted) +
                    Number(item?.finalAssignQuesionRejected) +
                    item.answerNotResponded
                )) *
              100
            }%`,
            padding: "0px",
            margin: "0px",
          }}
        >
          <div
            className="bar accepted"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              width: "100%",
            }}
          >
            <span className="bar-label">{`${Number(
              item?.finalAssignQuesionAccpted
            )}`}</span>
          </div>
        </NavLink>
      );
    }

    // Handle Rejected for User
    if (
      isUser &&
      Number(item?.finalAssignQuesionRejected) !== 0 &&
      activeLegend.includes("rejected")
    ) {
      bars.push(
        <NavLink
          key="rejected"
          onClick={() => {
            localStorage.setItem(
              "reportingQuestion",
              JSON.stringify(item.finalAssignQuesionRejectedIds)
            );
          }}
          to={{
            pathname: isUser
            ? "reporting-modules/all-module"
            : "/audit-listing/all-module",
            state: {
              reportingQuestion: item.finalAssignQuesionRejectedIds,
              userId: item?.userId,
            },
          }}
          style={{
            display: "block",
            width: `${
              (Number(item?.percentageRejected) /
                Number(
                  Number(item?.answered) +
                    Number(item?.totalAssignedQuestionForAnswered) +
                    Number(item?.finalAssignQuesionAccpted) +
                    Number(item?.finalAssignQuesionRejected) +
                    item.answerNotResponded
                )) *
              100
            }%`,
            padding: "0px",
            margin: "0px",
          }}
        >
          <div
            className="bar rejected"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              width: "100%",
            }}
          >
            <span className="bar-label">{`${Number(
              item?.finalAssignQuesionRejected
            )}`}</span>
          </div>
        </NavLink>
      );
    }

    if (
      (isUser
        ? item.answerNotResponded !== 0
        : Number(item?.notResponded) !== 0) &&
      activeLegend.includes("not-responded")
    ) {
      bars.push(
        <NavLink
          key="not-responded"
          onClick={() => {
            localStorage.setItem(
              "reportingQuestion",
              JSON.stringify(
                isUser
                  ? item.answerNotRespondedIds
                  : item.auditorNotRespondedIds
              )
            );
          }}
          to={{
            pathname: isUser
              ? "reporting-modules/all-module"
              : "/audit-listing/all-module",
            state: {
              reportingQuestion: isUser
                ? item.answerNotRespondedIds
                : item.auditorNotRespondedIds,
              userId: item?.userId,
            },
          }}
          style={{
            display: "block",
            width: isUser
              ? `${
                  (Number(item?.answerNotResponded) /
                    (Number(item?.answered) +
                      Number(item?.totalAssignedQuestionForAnswered) +
                      Number(item?.finalAssignQuesionAccpted) +
                      Number(item?.finalAssignQuesionRejected) +
                      Number(item?.answerNotResponded)) || 1) * 100
                }%`
              : `${Number(item?.percentageUnresponded)}%`,
            padding: "0px",
            margin: "0px",
          }}
        >
          <div
            className="bar not-responded"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              width: "100%",
            }}
          >
            <span className="bar-label">{`${Number(
              isUser ? item.answerNotResponded : item?.notResponded
            )}`}</span>
          </div>
        </NavLink>
      );
    }

    return bars;
  };

  return (
    <div
      style={{
        width: "100%",
        background: "white",
        borderRadius: "10px",
        padding: "10px",
        height: "100%",
      }}
    >
      <div style={{ width: "100%", background: "white", height: "10%" }}>
        <div
          style={{
            color: "#011627",
            fontSize: 22,
            height: "100%",
            paddingLeft: "5px",
            fontFamily: "Open Sans",
            fontWeight: "600",
          }}
        >
          {user === "user" ? "Data Owner Work Load" : "Auditor Work Load"}
        </div>
      </div>

      {teamWorkloadData && teamWorkloadData.length > 0 ? (
        <>
          <div
            style={{ height: "80%" }}
            className="scrollable-table mt-2 scroll-container"
          >
            <Table style={{ height: "95%" }} className="">
              <thead>
                <tr>
                  <th
                    className="name-column"
                    style={{ color: "#96A5B8", fontSize: "16px" }}
                  >
                    Name
                  </th>
                  <th
                    className="progress-column"
                    style={{ color: "#96A5B8", fontSize: "16px" }}
                  >
                    Progress
                  </th>
                </tr>
              </thead>
              <tbody className="scroll-container">
                {teamWorkloadData.map((item) => {
                  const isUser = user === "user";
                  if (
                    (isUser && item?.totalAssignedQuestionForAnswered !== 0) ||
                    (!isUser && item?.totalAssignedQuestionForAudit !== 0)
                  ) {
                    return (
                      <tr key={item.userId} style={{ padding: "20px 0px" }}>
                        <td
                          className="name-column"
                          style={{
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: "16px",
                          }}
                        >
                          {`${item.firstName} ${item.lastName}`}
                        </td>
                        <td className="progress-column">
                          <div
                            className="bar-container"
                            style={{ width: "100%", background: "#C3D4DB" }}
                          >
                            {renderBars(item)}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </Table>
          </div>

          <div
            className="legend"
            style={{
              width: "100%",
              background: "white",
              borderRadius: "10px",
              height: "5%",
            }}
          >
            {user === "audit" && (
              <div
                className={`legend-item ${
                  activeLegend.includes("accepted") ? "active" : ""
                }`}
                onClick={() => handleLegendClick("accepted")}
                style={{ cursor: "pointer" }}
              >
                <div className="legend-color accepted"></div>
                <div className="legend-text">Accepted</div>
              </div>
            )}
            {user === "user" && (
              <>
                <div
                  className={`legend-item ${
                    activeLegend.includes("answered") ? "active" : ""
                  }`}
                  onClick={() => handleLegendClick("answered")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="legend-color answered"></div>
                  <div className="legend-text">Answered</div>
                </div>
                <div
                  className={`legend-item ${
                    activeLegend.includes("accepted") ? "active" : ""
                  }`}
                  onClick={() => handleLegendClick("accepted")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="legend-color accepted"></div>
                  <div className="legend-text">Accepted</div>
                </div>
                <div
                  className={`legend-item ${
                    activeLegend.includes("rejected") ? "active" : ""
                  }`}
                  onClick={() => handleLegendClick("rejected")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="legend-color rejected"></div>
                  <div className="legend-text">Rejected</div>
                </div>
                <div
                  className={`legend-item ${
                    activeLegend.includes("assigned") ? "active" : ""
                  }`}
                  onClick={() => handleLegendClick("assigned")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="legend-color assigned"></div>
                  <div className="legend-text">Assigned</div>
                </div>
              </>
            )}
            <div
              className={`legend-item ${
                activeLegend.includes("not-responded") ? "active" : ""
              }`}
              onClick={() => handleLegendClick("not-responded")}
              style={{ cursor: "pointer" }}
            >
              <div className="legend-color not-responded"></div>
              <div className="legend-text">Not Responded</div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <img src={no} height={"150px"} width={"150px"} />
          </div>
        </>
      )}
    </div>
  );
};

export default TeamWorkLoad;
