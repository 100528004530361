import Select, { components } from "react-select"; // Import react-select
import React, { useState, useEffect,useMemo } from "react";
import Chart from "react-apexcharts"; // Assuming you are using ApexCharts




const MultipleBarWater = ({  timePeriods,
  locationOption,
  matchedDataWater,
  title,
  timePeriodValues, }) => {
    const colorMapping = {
      Groundwater: "#83bbd5",
      "Third-party water": "#11546f",
      "Surface water":"#11546f",
      "Municipal water": "#65b1b6",
      "Seawater": "#4a849f",
      "To Surface water": "#86caea",
      "To Groundwater": "#e74c3c",
      "To Sea water": "#3498db",
      "Sent to other parties": "#1abc9c",
      Biomass: "#e67e22",
      "Energy Consumption through other sources": "#95a5a6",
    };
  
    const [chartSeries, setChartSeries] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]); // State for selected products
    const [productValuesMap, setProductValuesMap] = useState({});
    const [chartOptions, setChartOptions] = useState({
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false, // Disable the three-line menu (toolbar)
        },
        stackType: "normal",
      },
      tooltip: {
        enabled: false, // Disable tooltip
      },
      plotOptions: {
        bar: {
          // borderRadius: 5,
          horizontal: false,
          columnWidth: "25%",
          // endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, { seriesIndex, dataPointIndex }) {
          const logValue = val; // Logarithmic value passed to tooltip
          const originalValue = Math.pow(10, logValue); // Reverse log to get original value
          return ``; // Display original value in tooltip
        },
        offsetY: 0, // Center the label vertically inside the bar
        style: {
          fontSize: "8px",
          colors: ["#fff"], // White text inside bars
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["#fff"],
      },
      xaxis: {
        categories: [], // Will be set dynamically
        title: {
          text: "Time Periods",
          style: {
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Arial",
            color: "#011627",
          },
        },
        labels: {
          style: {
            colors: "#7b91b0",
            fontSize: "12px",
            fontFamily: "Poppins",
          },
        },
      },
      yaxis: {
        logarithmic: false, // Enable logarithmic scale
        title: {
          text: "----------(KL)--------",
          style: {
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Arial",
            color: "#011627",
          },
        },
  
        min: 1, // Set minimum value to avoid logarithm of zero
        max: 15, // You can adjust this based on your data
        labels: {
          style: {
            colors: ["#7b91b0"],
            fontSize: "12px",
            fontFamily: "Poppins",
          },
          formatter: (value) => formatValue(value),
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val, { seriesIndex, dataPointIndex }) {
            const logValue = val; // Logarithmic value passed to tooltip
            const originalValue = Math.pow(10, logValue); // Reverse log to get original value
            return `${originalValue.toFixed(2)} KL`; // Display original value in tooltip
          },
        },
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5,
        },
      },
      legend: {
        show: true,
        position: "bottom", // Keep the legend at the bottom
        horizontalAlign: "center",
        markers:{
  
          horizontal: 10,
          vertical: 10,
  radius:10,
        },
        itemMargin: {
          horizontal: 10,
          vertical: 10,
  
        },
      },
    });
  
    const formatNumberWithIndianCommas = (number) => {
      const x = number.toString().split('.');
      let num = x[0];
      let lastThree = num.slice(-3);
      const rest = num.slice(0, -3);
      
      if (rest !== '') {
        lastThree = ',' + lastThree;
        const result = rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
        num = result + lastThree;
      } else {
        num = lastThree;
      }
      
      return x.length > 1 ? num + '.' + x[1] : num; // Just format the number without manipulating the input string
    };
  
    const formatValue = (value) => {
      if (value >= 1e6) {
        return `${(value / 1e6).toFixed(1)}M`; // Format millions
      } else if (value >= 1e3) {
        return `${(value / 1e3).toFixed(1)}K`; // Format thousands
      } else {
        return Math.round(value); // Format normal numbers
      }
    };
    
  
    useEffect(() => {
      // Initial data mapping for all products
      const initialProductValuesMap = {};
  
      const categories = Object.keys(timePeriods);
      const allOptions = getOptions(matchedDataWater); // Fetch all matchedDataWater options
  
      allOptions.forEach((option, index) => {
        const data = categories.map((key) => {
          const filteredData = matchedDataWater.filter(
            (item) => item.formDate === timePeriods[key]
          );
  
          const summedValue = filteredData.reduce((sum, item) => {
            const value = item.answer[index] ? item.answer[index][0] : 0; // Set to 0 if undefined
            return sum + (isNaN(Number(value)) || value === "" ? 0 : Number(value));
          }, 0);
  
          return summedValue; // Collect summed values for each time period
        });
  
        initialProductValuesMap[option] = data; // Store the mapped data
      });
  
      setProductValuesMap(initialProductValuesMap); // Set the permanent mapping
    }, [timePeriods, matchedDataWater]); // Only run once when the component mounts
  
  
    // Function to get the matchedDataWater options (e.g., "Electricity", "Petrol", etc.)
    const getOptions = (matchedDataWater) => {
      const optionsArray = matchedDataWater
        .flatMap((item) => item.question_details) // Flatten question_details array
        .filter((detail) => detail.option_type === "row") // Only "row" types
        .map((detail) => detail.option); // Extract the option values
  
      const uniqueOptions = [...new Set(optionsArray)]; // Remove duplicates
      const modifiedOptions = uniqueOptions.slice(1).reverse(); // Remove the first option and reverse the array
  
      return modifiedOptions;
    };
  
    // Multi-select options for react-select
    const productOptions = useMemo(() => {
      return getOptions(matchedDataWater).map((option) => ({
        label: option,
        value: option,
      }));
    }, [matchedDataWater]);
  
    useEffect(() => {
      if (productOptions.length > 0) {
        // Select the first five products
        const firstFiveProducts = productOptions.slice(0, 5);
        setSelectedProducts(firstFiveProducts);
      }
    }, [productOptions]); // Runs when productOptions changes
  
    useEffect(() => {
      if (locationOption.length > 1 && timePeriodValues.length === 1) {
        const categories = locationOption.map((loc) => loc.label); // X-axis labels (locations)
  
        const series = selectedProducts.map((selectedProduct) => {
          const optionIndex = getOptions(matchedDataWater).indexOf(selectedProduct.value);
  
          const data = categories.map((location) => {
            const locationId = locationOption.find(
              (loc) => loc.label === location
            ).id;
  
            const total = matchedDataWater
              .filter((item) => item.SourceId === locationId) // Match location
              .reduce((sum, item) => {
                const value = item.answer[optionIndex][0]; // Extract the value for the current option
                const numValue = Number(value);
                return sum + (isNaN(numValue) || value === "" ? 0 : numValue); // Sum values
              }, 0);
  
            return total; // Return the total for this location and option
          });
  
          return {
            name: selectedProduct.value,
            data: data,
            color: colorMapping[selectedProduct.value], // Assign color based on option
          };
        });
  
        setChartSeries(series);
        setChartOptions((prev) => ({
          ...prev,
          xaxis: {
            ...prev.xaxis,
            categories: categories, // Set x-axis labels (locations)
          },
        }));
      } else if (locationOption.length === 1 && timePeriodValues.length > 1) {
       
        const series = selectedProducts.map((selectedProduct) => {
          const data = productValuesMap[selectedProduct.value] || [];
          return {
            name: selectedProduct.value,
            data: data.map(value => (value > 0 ? Math.log10(value) : 0)), // Convert to log values
            color: colorMapping[selectedProduct.value],
          };
        });
        const categories = Object.keys(timePeriods).map((key) =>
          key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()
        );
    
        setChartSeries(series);
        setChartOptions((prev) => ({
          ...prev,
          xaxis: {
            ...prev.xaxis,
            categories:categories,
          },
        }));
  
      } else {
        // Handle default case or other scenarios similarly to the previous cases
        const options =
          selectedProducts.length > 0
            ? selectedProducts.map((p) => p.value)
            : getOptions(matchedDataWater); // Get selected products or all products
        const categories = Object.keys(timePeriods);
  
        const series = options.map((option, index) => {
          const data = categories.map((key) => {
            return matchedDataWater
              .filter((item) => item.formDate === timePeriods[key]) // Filter by formDate
              .reduce((sum, item) => {
                const value = item.answer[index][0]; // First element of inner array
                return (
                  sum + (isNaN(Number(value)) || value === "" ? 0 : Number(value))
                );
              }, 0);
          });
          return {
            name: option,
            data: data,
            color: colorMapping[option], // Assign color based on option
          };
        });
  
        setChartSeries(series);
      }
    }, [locationOption, timePeriodValues, matchedDataWater, selectedProducts]);
  
    // Handle the change in selected products
    const handleProductChange = (selected) => {
      if (selected.length === 0) {
        alert("You must select at least one matchedDataWater");
        return; // Prevent selecting zero products
      }
      if (selected.length > 5) {
        alert("You can only select up to 5 products");
        return; // Prevent selecting more than 5 products
      }
      setSelectedProducts(selected); // Update selected products state
    };
    
  
    const CustomOption = (props) => {
      const { isSelected, data } = props;
  
      return (
        <components.Option {...props}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* Square Box */}
            <div
              style={{
                width: "20px",
                height: "20px",
                border: "2px solid #3f88a5",
                borderRadius: "2px",
                backgroundColor: isSelected ? "transparent" : "transparent",
                marginRight: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Tick mark when selected */}
              {isSelected && (
                <span style={{ color: "white", fontSize: "14px" }}>✔</span>
              )}
            </div>
            {/* Option Label */}
            <span style={{ fontSize: "14px", fontWeight: 300 }}>
              {data.label}
            </span>
          </div>
        </components.Option>
      );
    };
  
    const CustomMultiValue = () => null;
  
    const CustomControl = (props) => {
      const { selectProps } = props;
      const { value, placeholder } = selectProps;
    
      return (
        <components.Control {...props}>
          {/* Placeholder or selected value */}
          {(!value || value.length === 0) && (
            <div
              style={{
                color: "#3f88a5",
                fontWeight: 600,
                fontSize: "13px",
                position: "absolute",
                left: "5px",
                pointerEvents: "none",
              }}
            >
         {/* {placeholder} */}
            </div>
          )}
          {/* Display only the first selected matchedDataWater */}
          {value && value.length > 0 && (
            <div style={{ color: "#3f88a5", marginLeft: "5px" }}>
              {value[0].label}
            </div>
          )}
    
          {/* Ensure you still render the child components like the dropdown indicator and input */}
          {props.children}
        </components.Control>
      );
    };
    const CustomClearIndicator = () => null; 
    return (
      <div className="container" style={{ height: "100%" }}>
        <div
          style={{
            height: "10%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "40%",
              fontSize: "20px",
              fontWeight: 600,
              color: "#011627",
            }}
          >
            {title}
          </div>
  
          <div style={{ width: "35%" }}>
            <Select
              isMulti
              options={productOptions}
              value={selectedProducts}
              onChange={handleProductChange}
              placeholder="Select Products"
              hideSelectedOptions={false} // Keep selected options in the dropdown
              className=""
              components={{
                Option: CustomOption,
                Control: CustomControl,
                MultiValue: CustomMultiValue,
                ClearIndicator:CustomClearIndicator
              }}
              closeMenuOnSelect={false} // Prevent dropdown from closing
              styles={{
                control: (base) => ({
                  ...base,
                  border: "2px solid #3f88a5",
                  borderRadius: "10px",
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 100, // Ensure the menu appears above other elements
                  border: "2px solid #3f88a5",
                  borderRadius: "10px",
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  color: "#3f88a5", // Change color of the dropdown arrow
                  padding: "0 10px", // Adjust padding for the indicator
                  fontSize: "20px", // Increase the font size of the indicator
                  minHeight: "20px", // Set a minimum height for the indicator
                  minWidth: "20px", // Set a minimum width for the indicator
                }),
                placeholder: (base) => ({
                  ...base,
                  position: "absolute", // Ensure the placeholder doesn't shift with input
                  top: "50%",
                  transform: "translateY(-50%)", // Vertically center the placeholder
                  pointerEvents: "none", // Disable interaction on the placeholder
                }),
                multiValue: (base) => ({
                  ...base,
                  background: "transparent",
                  border: "2px solid #3f88a5",
                  borderRadius: "10px",
                  marginTop: "0.5em",
                }),
                option: (provided, state) => ({
                  ...provided,
  
                  backgroundColor: state.isSelected
                    ? "transparent" // Selected option background color
                    : state.isFocused
                    ? "white" // Focused option background color
                    : "white", // Default option background color
                  color: state.isSelected ? "black" : "black", // Text color based on state
                  cursor: "pointer",
                }),
              }}
            />
          </div>
        </div>
        {/* Multi-select dropdown for selecting products */}
  
        <div style={{ height: "85%", marginTop: "5%" }}>
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={"100%"}
          />
        </div>
      </div>
    );
};

export default MultipleBarWater;
