import React, { useEffect } from "react";
import { Form, Row, Col, Dropdown, Modal, Button } from "react-bootstrap";
import { IoDownloadOutline } from "react-icons/io5";
import { DetailModal } from "./DetailModal";
import { useState } from "react";
import { FaUpload } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { apiCall } from "../../../../_services/apiCall";
import swal from "sweetalert";
import config from "../../../../config/config.json";
import { FaEdit } from "react-icons/fa";
const TrendsComponent = ({
  sourceData,
  answerId,
  menu,
  selectedPeriod,
  currentUserId,
  assignedToDetails,
  auditItem,
  financeObject,
  getAuditListing,
  auditorId,
  apiData,
  savedAnswers,
  item,
  // getReportingQuestions,
  answer,
  setAnswer,
  toDate,
  fromDate,
  sourceChange,
  singleItem,
}) => {
  const [selectedPeriodd, setSelectedPeriodd] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [selectedProcessName, setSelectedProcessName] = useState("");
  const [periodName, setPeriodName] = useState(""); // State for process name
  const [selectedUnit, setSelectedUnit] = useState("");
  const [answerIdReal, setAnswerIdReal] = useState();
  const [status, setStatus] = useState();
  const [note, setNote] = useState("");
  const [readingValue, setReadingValue] = useState("");
  const [meterList, setMeterList] = useState([]);
  const [processList, setProcessList] = useState([]);
  const [remark, setRemark] = useState("");
  const [answerr, setAnswerr] = useState({});
  const [check, setCheck] = useState(false);
  const [edit, setEdit] = useState(true);
  const [auditButton, setAuditButton] = useState(false);
  const [comment, setComment] = useState([[]]);
  const [target, setTarget] = useState();

  const [proofDocument, setProofDocument] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [showData, setShowData] = useState("");
  const [commentModal, setCommentModal] = useState(false);
  const [comments, setComments] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState();
  const [confirmTarget, setConfirmTarget] = useState(false);

  const itemHeight = "6vh"; // Adjust height as per design
  const inputStyle = {
    height: itemHeight,
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #ccc",

    backgroundColor: "white",
    borderRadius: "5px",
  };

  const handleDoubleClick = (data) => {
    if (data.trim() !== "") {
      // Check if data is not an empty string
      setShowData(data);
      setIsModalOpen(true);
    }
  };


  const initializeAnswer = () => {
    if (item && item.questionId && savedAnswers && savedAnswers.length) {
      // const matchedAnswer = savedAnswers.find(
      //   (savedAnswer) => savedAnswer.questionId == item.questionId
      // );
      let matchedAnswer;
      if (item?.frequency == "CUSTOM") {
        matchedAnswer = savedAnswers.find(
          (savedAnswer) =>
            savedAnswer.questionId == item.questionId &&
            savedAnswer.toDate == toDate &&
            savedAnswer.fromDate == fromDate &&
            savedAnswer.sourceId == sourceChange
        );
        setAnswerIdReal(matchedAnswer?.id);
      } else {
        matchedAnswer = savedAnswers.find(
          (savedAnswer) => savedAnswer.questionId == item.questionId
        );
        setAnswerIdReal(matchedAnswer?.id);
      }
      if (matchedAnswer) {
        setCheck(true);
        setAnswerIdReal(matchedAnswer?.id);
        setStatus(matchedAnswer?.status);
        try {
          const answerObject = JSON.parse(matchedAnswer.answer);

          // Extract the process ID
          const processId = answerObject.process || "";

          // Find the process name from processList
          const matchedProcess = processList.find(
            (process) => process.id === parseInt(processId)
          );

          const processName = matchedProcess ? matchedProcess.process : "";

          if (matchedAnswer.sourceId) {
            const sourceObject = meterList?.find(
              (source) => source.id == answerObject.source
            );
            if (sourceObject) {
              // Set selectedSource to the sourceObject's name or location
              setSelectedSource(sourceObject.location); // Assuming 'name' is the correct property
              // If 'location' is the correct property, use sourceObject.location instead
            }
          }

          if (Array.isArray(matchedAnswer.proofDocumentNote)) {
            // Check if the first element is an array (indicating a 2D array)
            if (Array.isArray(matchedAnswer.proofDocumentNote[0])) {
              setComment(matchedAnswer.proofDocumentNote);
            } else {
              // It's a 1D array, so wrap it in another array to make it 2D
              setComment([matchedAnswer.proofDocumentNote]);
            }
          } else {
            // If matchedAnswer.proofDocument is not an array, set it to an empty 2D array
            setComment([[]]);
          }

          // Set the state variables
          setSelectedPeriodd(processId); // Store the process ID
          setPeriodName(processName); // Set the period name

          setSelectedUnit(answerObject.unit || "");
          setReadingValue(answerObject?.readingValue);
          setNote((prevState) => {
            // Check if matchedAnswer.note is a string
            if (typeof matchedAnswer.note === "string") {
              return [[matchedAnswer.note]]; // Wrap the string in a 2D array
            }

            // Check if matchedAnswer.note is a 2D array
            if (
              Array.isArray(matchedAnswer.note) &&
              Array.isArray(matchedAnswer.note[0])
            ) {
              return matchedAnswer.note; // Use it as is
            }

            // If matchedAnswer.note is not a string or a 2D array, return an empty 2D array
            return [[""]];
          });
          if (Array.isArray(matchedAnswer.proofDocument)) {
            // Check if the first element is an array (indicating a 2D array)
            if (Array.isArray(matchedAnswer.proofDocument[0])) {
              setProofDocument(matchedAnswer.proofDocument);
            } else {
              // It's a 1D array, so wrap it in another array to make it 2D
              setProofDocument([matchedAnswer.proofDocument]);
            }
          } else {
            // If matchedAnswer.proofDocument is not an array, set it to an empty 2D array
            setProofDocument([[]]);
          }
          setAnswer((prevState) => ({
            ...prevState,
            questionId: item?.questionId,
            moduleId: item?.moduleId,
            questionType: item?.questionType,
            frequency: item?.frequency,
            questionTitle: item?.title,

            fromDate:fromDate,
            toDate:toDate,
            response: matchedAnswer.answer || "",
            comment: matchedAnswer?.comment || [[]],
            note: (() => {
              // Check if matchedAnswer.note is a string
              if (typeof matchedAnswer.note === "string") {
                return [[matchedAnswer.note]]; // Wrap the string in a 2D array
              }

              // Check if matchedAnswer.note is a 2D array
              if (
                Array.isArray(matchedAnswer.note) &&
                Array.isArray(matchedAnswer.note[0])
              ) {
                return matchedAnswer.note; // Use it as is
              }

              // If matchedAnswer.note is not a string or a 2D array, return an empty 2D array
              return [[""]];
            })(),
            proofDocument: (() => {
              if (Array.isArray(matchedAnswer.proofDocument)) {
                // Check if the first element is an array (indicating a 2D array)
                if (Array.isArray(matchedAnswer.proofDocument[0])) {
                  return matchedAnswer.proofDocument; // Use it as is
                } else {
                  // It's a 1D array, so wrap it in another array to make it 2D
                  return [matchedAnswer.proofDocument];
                }
              } else {
                // If matchedAnswer.proofDocument is not an array, set it to an empty 2D array
                return [[]];
              }
            })(),
          }));
        } catch (error) {
          console.error("Error parsing matchedAnswer.answer:", error);
        }
      } else {
        setSelectedPeriodd(""); // Store the process ID
        setPeriodName(""); // Set the period name
        setAnswer((prevState) => ({
          ...prevState,
          questionId: item?.questionId,
          fromDate:fromDate,toDate:toDate,
          moduleId: item?.moduleId,
          questionTitle: item?.title,


          questionType: item?.questionType,
          frequency: item?.frequency,
          note: [[""]],
        }));

        setSelectedUnit("");
        setReadingValue("");
        setNote("");
        setProofDocument([[]]);
      }
    }else{
      setCheck(true);
    }
  };

  const initializeTarget = () => {
    if (item && item.questionId && apiData && apiData.length) {
      // const matchedAnswer = savedAnswers.find(
      //   (savedAnswer) => savedAnswer.questionId == item.questionId
      // );
      let matchedAnswer;
      if (item?.frequency == "CUSTOM") {
        matchedAnswer = apiData.find(
          (savedAnswer) =>
            savedAnswer.questionId == item.questionId &&
            savedAnswer.toDate == toDate &&
            savedAnswer.fromDate == fromDate &&
            savedAnswer.sourceId == sourceChange
        );
        setTarget(matchedAnswer?.targetData);
      } else {
        matchedAnswer = apiData.find(
          (savedAnswer) => savedAnswer.questionId == item.questionId
        );
        setTarget(matchedAnswer?.targetData);
      }
      if (matchedAnswer) {
      }
    }
  };

  const cancelComment = () => {
    setCommentModal(false);
    setFileToDelete(null);
  };

  const cancelTarget = () => {
    setConfirmTarget(false);
  };

  const filteredDocs = (proofDocument[0] || []).filter(
    (doc) => doc && typeof doc === "string" && doc.trim() !== ""
  );

  const filteredComments = Array.isArray(comment[0])
    ? comment[0].filter(
        (c, index) =>
          index < filteredDocs.length && (typeof c === "string" || c === "")
      )
    : [];

  useEffect(() => {
    console.log("from changed", fromDate)
    initializeAnswer();
  }, [savedAnswers, toDate, fromDate, item, sourceChange]);

  // useEffect(() => {
  //   initializeTarget();
  // }, [apiData, toDate, fromDate, item, sourceChange]);

  useEffect(() => {
    if (sourceData) {
      const locationArray = sourceData?.reverse().map((item) => ({
        id: item.id,
        location: `${item?.location?.area}, ${item?.location?.city}, ${item?.location?.state}, ${item?.location?.country}, ${item?.location?.zipCode}`,
      }));
      if (locationArray && locationArray.length) {
        setMeterList(locationArray);
      }
      initializeAnswer();
      // initializeTarget();
    }
  }, [sourceData]);

  const handleEditClick = () => {
    setEdit(!edit);
  };

  const uploadFile = async (files) => {
    const selectedFile = files.target.files[0];
    const timestamp = new Date().getTime(); // Generate a timestamp
    const fileName = `${timestamp}_${selectedFile.name}`;

    const formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("fileName", fileName);
    formdata.append("filePath", "yasil/");

    const requestOptions = {
      header: {
        "Content-Type": "multipart/form-data", // Set the Content-Type header
      },
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${
          config.AUTH_API_URL_COMPANY
        }uploadFile?current_role=${localStorage.getItem("role")}`,
        requestOptions
      );
      const result = await response.text();
      const url = JSON.parse(result);

      // Append the new file URL to the list of proofDocument
      setProofDocument((prevState) => {
        const newProofDocument = [...prevState];

        if (!newProofDocument[0]) {
          newProofDocument[0] = [];
        }

        newProofDocument[0] = [...newProofDocument[0], url.url];

        return newProofDocument;
      });
      setAnswer((prevState) => {
        const newProofDocument = [...prevState.proofDocument];

        // If currentPage doesn't exist yet, initialize it as an empty array
        if (!newProofDocument[0]) {
          newProofDocument[0] = [];
        }

        // Update the specific page
        newProofDocument[0] = [...newProofDocument[0], url.url];

        return {
          ...prevState,
          questionId: item?.questionId,
          moduleId: item?.moduleId,
          questionType: item?.questionType,
          questionTitle:item?.title,
          frequency: item?.frequency,
          proofDocument: newProofDocument,
        };
      });
      setFile(`${timestamp}_${selectedFile.name}`);
      setCommentModal(true);
    } catch (error) {
      if (error) {
        swal({
          icon: "error",
          title: error,
          timer: 1000,
        });
      }
    }
  };
  const confirmDelete = () => {
    setProofDocument((prevState) => {
      // Clone the current proofDocument array
      const newProofDocument = [...prevState];

      // Update the specific page by filtering out the fileToDelete
      newProofDocument[0] = newProofDocument[0].filter(
        (url) => url !== fileToDelete
      );

      return newProofDocument;
    });
    setShowModal(false);
    setFileToDelete(null); // Clear the file to delete
  };

  // Cancel deletion
  const cancelDelete = () => {
    setShowModal(false);
    setFileToDelete(null);
  };

  const handleDeleteClick = (url) => {
    setFileToDelete(url);
    setShowModal(true);
  };

  const handleFileDownload = (url) => {
    const replacedUrl = url.replace(
      "https://riu-bucket.s3.ap-south-1.amazonaws.com",
      "https://copyadatafromawstoazure.blob.core.windows.net/uploads"
    );
    const link = document.createElement("a");
    link.href = replacedUrl;
    link.target = "_blank"; // Opens in a new tab
    link.download = replacedUrl.split("/").pop();
    document.body.appendChild(link); // Append to body
    link.click();
    document.body.removeChild(link); // Clean up
  };

  const getProcess = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getProcess`,
      {},
      { type: "ALL" },
      "GET"
    );
    if (isSuccess) {
      setProcessList(data?.data?.reverse());
    }
  };

  // const handleReadingValue = (e) => {
  //   setReadingValue(Number(e.target.value));
  //   const value = e.target.value;
  //   setAnswerr((prevAnswerr) => ({
  //     ...prevAnswerr,
  //     questionId: item?.questionId,
  //     moduleId: item?.moduleId,
  //     questionType: item?.questionType,
  //     questionTitle: item?.title,
  //     fromDate:fromDate,
  //     toDate:toDate,
  //     frequency: item?.frequency,
  //     readingValue: Number(value),
  //   }));
  //   setAnswer((prevAnswer) => ({
  //     ...prevAnswer,
  //     readingValue: Number(value),
  //     questionTitle: item?.title,
  //   }));
  // };

  const handleReadingValue = (e) => {
    const value = e.target.value; // Keep it as a string initially
    setReadingValue(value); // Update readingValue as string
    
    // // Convert to Number only when updating the dependent state
    // const numericValue = parseFloat(value) || 0; // Safely parse, fallback to 0 for invalid numbers
    setAnswerr((prevAnswerr) => ({
      ...prevAnswerr,
      questionId: item?.questionId,
      moduleId: item?.moduleId,
      questionType: item?.questionType,
      questionTitle: item?.title,
      fromDate: fromDate,
      toDate: toDate,
      frequency: item?.frequency,
      readingValue: value,
    }));
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      readingValue: parseFloat(value) || 0,
      questionTitle: item?.title,
    }));
  };
  
  // const handlePeriodSelect = (key) => {
  //   setSelectedPeriodd(key);
  //   setAnswerr((prevAnswerr) => ({
  //     ...prevAnswerr,
  //     process: key,
  //   }));
  // };
  // const handlePeriodSelect = (key) => {
  //   // Find the selected process by ID
  //   const selectedProcess = processList.find(
  //     (item) => item.id === parseInt(key)
  //   );

  //   if (selectedProcess) {
  //     // Set the process name and ID
  //     setPeriodName(selectedProcess.process); // Set the process name
  //     setSelectedPeriodd(selectedProcess.id); // Set the process ID

  //     // Update the answer object with the process ID
  //     setAnswerr((prevAnswerr) => ({
  //       ...prevAnswerr,
  //       questionId: item?.questionId,
  //       moduleId: item?.moduleId,
  //       questionType: item?.questionType,
  //       frequency: item?.frequency,
  //       process: selectedProcess.id, // Store the process ID
  //     }));
  //   }
  // };
  const handleRemarkChange = (e) => {
    const newRemark = e.target.value;
    setRemark(newRemark);
  };

  const handleComment = (e) => {
    setComments(e.target.value);
  };

  const handleCommentChange = (value) => {
    setComment((prevState) => {
      const newProofDocument = [...prevState];

      if (!newProofDocument[0]) {
        newProofDocument[0] = [];
      }

      newProofDocument[0] = [...newProofDocument[0], value];

      return newProofDocument;
    });

    setAnswer((prevState) => {
      // Clone the current proofDocument
      const newProofDocument = [...prevState.comment];

      // If currentPage doesn't exist yet, initialize it as an empty array
      if (!newProofDocument[0]) {
        newProofDocument[0] = [];
      }

      // Update the specific page
      newProofDocument[0] = [...newProofDocument[0], value];

      // Return the updated state
      return {
        ...prevState,
        questionId: item?.questionId,
        moduleId: item?.moduleId,
        questionType: item?.questionType,
        frequency: item?.frequency,
        proofDocumentNote: newProofDocument,
      };
    });
  };

  const saveComment = () => {
    // Save the comment and close the modal
    handleCommentChange(comments);

    setCommentModal(false);
  };
  const handleSourceSelect = (key) => {
    // Find the selected item by its id
    const selectedItem = meterList?.find((item) => item.id.toString() === key);
    if (selectedItem) {
      // Set the selected source to the item's location (name)
      setSelectedSource(selectedItem.location);
      // Update the answer state with the selected item's id
      setAnswer((prevAnswer) => ({
        ...prevAnswer,
        sourceId: Number(key),
      }));
    }
  };
  const handleNoteChange = (e) => {
    const newNote = e.target.value;
    setNote([[newNote]]);
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      questionId: item?.questionId,
      moduleId: item?.moduleId,
      questionType: item?.questionType,
      frequency: item?.frequency,
      note: [[newNote]],
    }));
  };

  const handleAccept = async () => {
    const { isSuccess, error, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
      {},
      {
        questionId: item.questionId,
        answerId: answerIdReal,
        questionType: item.questionType,
        remark: remark,
        validation: "ACCEPTED",
        financialYearId: financeObject,
      },
      "POST"
    );

    if (isSuccess) {
      setRemark("");
      setAuditButton(true);
      getAuditListing();
    }

    if (error) {
      swal({
        icon: "error",
        title: data.message,
        timer: 1000,
      });
    }
  };

  const handleReject = async () => {
    if (remark) {
      const { isSuccess, error, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
        {},
        {
          questionId: item.questionId,
          answerId: answerIdReal,
          questionType: item.questionType,
          remark: remark,
          validation: "REJECTED",
          financialYearId: financeObject,
        },
        "POST"
      );

      if (isSuccess) {
        setRemark("");
        getAuditListing();
      }

      if (error) {
        swal({
          icon: "error",
          title: data.message,
          timer: 1000,
        });
      }
    } else {
      swal({
        icon: "error",
        title: "Please enter remark",
        timer: 1000,
      });
    }
  };

  const handleSubmit = async () => {
    // if (readingValue > target) {
    //   if (confirmTarget) {
        const { isSuccess, data } = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}saveAnswerReportingQuestion`,
          {},
          {
            ...answer,
            financialYearId: financeObject,

            answer: JSON.stringify({
              ...answerr,
              unit: item?.details[0]?.option,
            }),
          },
          "POST"
        );

        if (isSuccess) {
          setTarget(false);
        }
    //   } else {
    //     setConfirmTarget(true);
    //   }
    // } else {
    //   const { isSuccess, data } = await apiCall(
    //     `${config.POSTLOGIN_API_URL_COMPANY}saveAnswerReportingQuestion`,
    //     {},
    //     {
    //       ...answer,
    //       financialYearId: financeObject,

    //       answer: JSON.stringify({
    //         ...answerr,
    //         unit: item?.details[0]?.option,
    //       }),
    //     },
    //     "POST"
    //   );

    //   if (isSuccess) {
    //     setTarget(false);
    //   }
    // }
  };

  useEffect(() => {
    getProcess();
  }, []);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "20px",
      width: "100%",
    },
    text: {
      color: "rgba(0, 0, 0, 0.70)",
      fontSize: "12px",
      fontFamily: "Open Sans",
      fontWeight: 400,
    },
    uploadDiv: {
      width: "100%",
      backgroundColor: "#3F88A5",
      borderRadius: "10px",
      height: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      color: "white",
      position: "relative",
      border: "2px dashed #3F88A5",
      transition: "background-color 0.3s ease",
    },
    uploadDivHover: {
      backgroundColor: "#30707E", // Darker blue on hover
    },
    icon: {
      position: "absolute",
      right: "10px",
      fontSize: "10px",
      color: "white",
    },
    fileInput: {
      display: "none",
    },
    formGroup: {
      marginBottom: "15px",
    },
  };

  return (
    <Form>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        {check &&
          assignedToDetails?.assignedTo?.length > 0 &&
          assignedToDetails.assignedTo.some(
            (id) => parseInt(id, 10) === currentUserId
          ) && (
            <FaEdit
              style={{
                cursor: "pointer",
                marginLeft: "10px",
                height: "20px",
                width: "20px",
                color: edit ? "black" : "#BFD7E0",
              }}
              onClick={handleEditClick}
            />
          )}
      </div>
      <Row>
        

        <Col md={3}>
          <Form.Group controlId="formInput11" style={styles.formGroup}>
            <Form.Label className="custom-label">Reading Value</Form.Label>
            <Form.Control
              readOnly={
                menu === "audit" ||
                (assignedToDetails?.assignedTo?.length > 0 &&
                  !assignedToDetails?.assignedTo?.some(
                    (id) => parseInt(id, 10) === currentUserId
                  )) ||
                (check && edit) ||
                (assignedToDetails?.dueDate &&
                  new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                    new Date().setHours(0, 0, 0, 0))
              }
              style={{backgroundColor:"#Dfebef" }}
              type="text"
              as="textarea"
              onChange={handleReadingValue}
              value={readingValue}
              onDoubleClick={() => handleDoubleClick(readingValue)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <Form.Group controlId="formInput10" style={styles.formGroup}>
            <Form.Label className="custom-label">Unit</Form.Label>
            <Form.Control
              as="textarea"
              readOnly={
                menu === "audit" ||
                (assignedToDetails?.assignedTo?.length > 0 &&
                  !assignedToDetails?.assignedTo?.some(
                    (id) => parseInt(id, 10) === currentUserId
                  )) ||
                (check && edit) ||
                (assignedToDetails?.dueDate &&
                  new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                    new Date().setHours(0, 0, 0, 0))
              }
              type="text"
              style={{backgroundColor:"#Dfebef" }}
              value={item?.details[0]?.option}
              onDoubleClick={() => handleDoubleClick(item?.details[0]?.option)}
            />
          </Form.Group>
        </Col>

        <Row>
          <Col md={6}>
            {menu !== "audit" && (
              <Form.Group
                controlId="formFile"
                className="custom-file-upload"
                style={{ marginBottom: "5%" }}
              >
                <Form.Label className="custom-label">
                  Upload Attachment
                </Form.Label>
                <div className="file-upload-wrapper">
                  <label className="upload-btn">
                    <div style={{ height: "12px", width: "12px" }}>
                      <FaUpload height={"12px"} width={"12px"} />
                    </div>
                    <span>Upload a file</span>
                    <Form.Control
                      type="file"
                      disabled={
                        menu === "audit" ||
                        (assignedToDetails?.assignedTo?.length > 0 &&
                          !assignedToDetails?.assignedTo?.some(
                            (id) => parseInt(id, 10) === currentUserId
                          )) ||
                        (check && edit) ||
                        (assignedToDetails?.dueDate &&
                          new Date(assignedToDetails.dueDate).setHours(
                            0,
                            0,
                            0,
                            0
                          ) < new Date().setHours(0, 0, 0, 0))
                      }
                      style={{ display: "none" }} // Hide the default file input
                      onChange={(e) => uploadFile(e)}
                    />
                  </label>
                </div>
              </Form.Group>
            )}
          </Col>
          <>
            {filteredDocs && filteredDocs.length > 0 && (
              <Row
                style={{
                  backgroundColor: "#DFEBEF",
                  width: "100%",
                  padding: "20px",
                  borderRadius: "8px",
                  marginLeft: "0px",
                  marginTop: menu !== "audit" ? "0%" : "3%",
                }}
              >
                {filteredDocs?.length > 0 &&
                  filteredDocs?.map((url, index) => {
                    const docSegments = url.split("/");
                    const docFileName =
                      docSegments.length > 1
                        ? docSegments[docSegments.length - 2] ===
                          docSegments[docSegments.length - 1]
                          ? docSegments[docSegments.length - 1]
                          : docSegments[docSegments.length - 1]
                        : url;

                    let commentText;
                    if (
                      filteredComments.length > 0 &&
                      index < filteredComments.length
                    ) {
                      commentText =
                        filteredComments[index] !== 0
                          ? filteredComments[index]
                          : ""; // Check if commentText is 0
                    } else {
                      // Handle the case where the filteredComments array is empty or index is out of bounds
                      console.log(
                        "No valid comment found for the given index."
                      );
                    }

                    return (
                      <Row key={index} style={{ marginBottom: "10px" }}>
                        <Col md={6}>
                          <div style={inputStyle}>
                            <span
                              style={{
                                marginLeft: 10,
                                marginRight: 10,
                                wordBreak: "break-all",
                                flexGrow: 1,
                                fontSize: "12px",
                                maxWidth: "70%",
                              }}
                              title={docFileName}
                            >
                              {decodeURIComponent(docFileName).slice(0, 50)}
                            </span>

                            <IoDownloadOutline
                              style={{
                                marginLeft: 10,
                                cursor: "pointer",
                                height: "20px",
                                width: "20px",
                                color: "black",
                              }}
                              onClick={() => handleFileDownload(url)}
                              title="Download File"
                            />

                            {!(
                              menu === "audit" ||
                              (assignedToDetails?.assignedTo?.length > 0 &&
                                !assignedToDetails?.assignedTo?.some(
                                  (id) => parseInt(id, 10) === currentUserId
                                ))
                            ) && (
                              <RiDeleteBinLine
                                style={{
                                  cursor: "pointer",
                                  height: "20px",
                                  width: "20px",
                                  color: "black",
                                  marginLeft: "1rem",
                                }}
                                onClick={() => handleDeleteClick(url)}
                                title="Remove File"
                              />
                            )}
                          </div>
                        </Col>

                        <Col md={6} style={{ marginLeft: "1.8rem" }}>
                          <div style={inputStyle}>
                            <Form.Control
                              type="text"
                              defaultValue={commentText || ""} // If commentText is 0, it will be replaced by an empty string
                              readOnly={
                                menu === "audit" ||
                                (assignedToDetails?.assignedTo?.length > 0 &&
                                  !assignedToDetails?.assignedTo?.some(
                                    (id) => parseInt(id, 10) === currentUserId
                                  )) ||
                                (check && edit) ||
                                (assignedToDetails?.dueDate &&
                                  new Date(assignedToDetails.dueDate).setHours(
                                    0,
                                    0,
                                    0,
                                    0
                                  ) < new Date().setHours(0, 0, 0, 0))
                              }
                              placeholder="No Comment"
                              style={{
                                height: "100%",
                                width: "100%",
                                border: "none",
                                borderRadius: "5px",
                                border: "1.5px solid #3F88A5",
                                borderColor: "#3F88A5",
                                background: "#DFEBEF",
                                backgroundColor: "#DFEBEF",
                                paddingLeft: "10px",
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </Row>
            )}

            {/* Confirmation Modal */}
            <Modal show={showModal} onHide={cancelDelete} centered>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete this file?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={cancelDelete}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={confirmDelete}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        </Row>

        {item?.note && (
          <Row>
            <Col md={12}>
              <Form.Group controlId="formInput12" style={styles.formGroup}>
                <Form.Label className="custom-label">Note</Form.Label>
                <Form.Control
                  as="textarea"
                  readOnly={
                    menu === "audit" ||
                    (assignedToDetails?.assignedTo?.length > 0 &&
                      !assignedToDetails?.assignedTo?.some(
                        (id) => parseInt(id, 10) === currentUserId
                      )) ||
                    (check && edit) ||
                    (assignedToDetails?.dueDate &&
                      new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                        new Date().setHours(0, 0, 0, 0))
                  }
                  style={{backgroundColor:"#Dfebef" }}
                  type="text"
                  value={note[0][0]}
                  onChange={handleNoteChange}
                  onDoubleClick={() => handleDoubleClick(note)}
                />
              </Form.Group>
            </Col>
          </Row>
        )}
      </Row>
      {menu === "audit" && (
        <Col md={8}>
          <Form.Group controlId="formInput12">
            <Form.Label className="custom-label">Remark</Form.Label>
            <Form.Control
              as="textarea"
              style={{backgroundColor:"#Dfebef" }}
              readOnly={
                menu === "audit" ||
                (assignedToDetails?.assignedTo?.length > 0 &&
                  !assignedToDetails?.assignedTo?.some(
                    (id) => parseInt(id, 10) === currentUserId
                  )) ||
                (check && edit) ||
                (assignedToDetails?.dueDate &&
                  new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                    new Date().setHours(0, 0, 0, 0))
              }
              type="text"
              value={remark}
              onChange={handleRemarkChange}
            />
          </Form.Group>
        </Col>
      )}

      <DetailModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        data={showData}
      />

      <div className="d-flex justify-content-between align-items-center mt-3">
        <button
          className="btn btn-secondary"
          style={{
            borderColor: "white",
            backgroundColor: "transparent",
            padding: "1%",
            paddingLeft: "1.5%",
            paddingRight: "1.5%",
          }}
        ></button>

        <div
          className="d-flex"
          style={{ width: "30%", justifyContent: "space-between" }}
        >
          {/* <button
            className="btn btn-primary me-5x"
            style={{
              borderColor: "white",
              backgroundColor: "transparent",
              padding: "2%",
              paddingLeft: "2%",
              paddingRight: "2%",
              paddingTop: "3%",
              paddingBottom: "3%",
            }}
          >
            Save the answer
          </button> */}

          {menu === "audit" ? (
            <>
              {auditItem &&
                auditItem?.matchingAuditors.find(
                  (d) => d.answerId == answerIdReal
                )?.auditerId == currentUserId &&
                answerIdReal && (
                  <>
                    {auditItem?.matchingAuditors.find(
                      (d) => d.answerId == answerIdReal
                    )?.remark == null ||
                    auditItem?.matchingAuditors
                      .find((d) => d.answerId == answerIdReal)
                      ?.remark?.every((remark) => remark.id != currentUserId) ||
                    auditItem.matchingAuditors?.some(
                      (auditor) =>
                        Number(auditor?.auditerId) === Number(currentUserId)
                    ) ? (
                      <>
                        <button
                          className="btn"
                          onClick={handleAccept}
                          type="button"
                          style={{
                            borderColor: "white",
                            backgroundColor: "#3F88A5",
                            padding: "2%",
                            paddingLeft: "10%",
                            paddingRight: "10%",
                          }}
                        >
                          Accept
                        </button>
                        <button
                          className="btn"
                          onClick={handleReject}
                          type="button"
                          style={{
                            borderColor: "white",
                            backgroundColor: "#3F88A5",
                            padding: "1%",
                            paddingLeft: "10%",
                            paddingRight: "10%",
                          }}
                        >
                          Reject
                        </button>
                      </>
                    ) : status === "ACCEPTED" || status === "REJECTED" ? (
                      <p>This answer has been audited.</p>
                    ) : null}
                  </>
                )}
            </>
          ) : (
            assignedToDetails?.assignedTo?.length > 0 &&
            assignedToDetails?.assignedTo?.some(
              (id) => parseInt(id, 10) === currentUserId
            ) && (
              <button
                className="btn btn-primary"
                onClick={() => handleSubmit(0)}
                disabled={
                  item?.frequency === "CUSTOM" 
                    ? !fromDate 
                      ? true 
                      : false 
                    : false
                }
                style={{
                  borderColor: "white",
                  backgroundColor: "#3F88A5",
                  padding: "2%",
                  paddingLeft: "10%",
                  paddingRight: "10%",
                }}
              >
                Submit
              </button>
            )
          )}
        </div>
      </div>
      <Modal show={confirmTarget} onHide={cancelTarget} size="lg" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formComment">
            <Form.Label>
               Reading value {`${readingValue}`} is greater than the target {`${target}`} set in the settings, you sure
              you want to continue?{" "}
            </Form.Label>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelTarget}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={commentModal} onHide={cancelComment} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Add a comment for file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formComment">
            <div>{file}</div>
            <Form.Label>Please provide a comment (optional):</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={comments}
              onChange={(e) => handleComment(e)}
              placeholder="Enter your comment here"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelDelete}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveComment}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export default TrendsComponent;
