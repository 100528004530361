import React from "react";
import TrainingBarFourtyEight from "./FrameworkFourtyEight/TrainingBarFourtyEight";
import TrainingBarComponent from "./FrameworkOne/TrainingarComponent";
import TrainingBarFourtyEightTwo from "./FrameworkFourtyEight/TrainingBarTwo";

const TrainingSingleLocSingleTime = ({
  companyFramework,

  timePeriods,
  dataOne,
  number,
  titleOne,
  dataThree,
  dataTwo,
  titleTwo,

  diversity,
  titleThree,
  titleFour,
  titleFive,
  brief,
  graphData,
  type,

  locationOption,

  keyTab,
  timePeriodValues,
}) => {
  return companyFramework.includes(1) ? 
  (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "25vh", marginBottom: "3%" }}
      >
        <div style={{ width: "100%" }}>
            <TrainingBarComponent number={0} dataOne={dataOne} title={"Health & Safety Measures"}/>
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "25vh", marginBottom: "3%" }}
      >
        <div style={{ width: "100%" }}>
        <TrainingBarComponent number={1} dataOne={dataOne} title={"On Skill Upgradation"}/>

        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "25vh", marginBottom: "3%" }}
      >
        <TrainingBarComponent number={number} dataOne={dataThree} title={"Human Rights"}/>
        
      </div>
    </div>
  ) : (
    <>
      <div className="d-flex flex-column flex-space-between">
        <div
          className="d-flex flex-row flex-space-between"
          style={{ height: "30vh", marginBottom: "3%" }}
        >
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "45%",
            }}
          >
            <div style={{ height: "80%" }}>
                <TrainingBarFourtyEight brief={brief}/>
            </div>

            <div style={{ height: "0%" }}>

            </div>
          </div>
          <div
            className="secondhalfprogress"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "55%",
            }}
          >
            <div style={{ height: "80%" }}>
            <TrainingBarFourtyEightTwo brief={brief}/>

            </div>
            <div style={{ height: "0%" }}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingSingleLocSingleTime;
