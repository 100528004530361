import React from "react";
import DiversityMultiLoc from "./FrameworkOne/DiversityMultiLoc";
import DiversityMultiLocTwo from "./FrameworkOne/DiversityMultiLocTwo";
import DiversityMultiLocSingle from "./FrameworkFourtyEight/DiversityMultiLocSingle";
import DiversityMultiLocSingleAge from "./FrameworkFourtyEight/DiversityMultiLocSingleAge";


const DiversitySingleTimeMultLoc = ({
  companyFramework,
  sectorQuestionAnswerDataForGraph,
  timePeriods,
  dataOne,
  titleOne,
  dataTwo,
  titleTwo,
  diversity,
  titleThree,
  titleFour,titleFive,
  graphData,
  totalConsumptionRenewable,
  totalConsumptionNonRenewable,
  brief,
  locationOption,
  renewableEnergy,
  nonRenewableEnergy,
  keyTab,
  timePeriodValues,
}) => {
 
  return companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "120vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "49%" }}>
            <DiversityMultiLoc horizontal={false}   title={titleOne}  timePeriods={timePeriods} matchedDataWaste={dataOne}/>
         
          </div>

          <div style={{ height: "49%" }}>
            <DiversityMultiLocTwo horizontal={false}  title={titleFour} timePeriods={timePeriods} matchedDataWaste={dataOne}/>
        
          </div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "49%" }}>
          <DiversityMultiLoc horizontal={true}  title={titleTwo}  timePeriods={timePeriods} matchedDataWaste={dataTwo}/>
       
          </div>
          <div style={{ height: "49%" }}>
          <DiversityMultiLocTwo horizontal={true}  title={titleFive} timePeriods={timePeriods} matchedDataWaste={dataTwo}/>

         
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "60vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <DiversityMultiLoc  title={titleThree}  timePeriods={timePeriods} matchedDataWaste={diversity}/>
         
          </div>

          
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "49%" }}>
       
          </div>
          <div style={{ height: "49%" }}>

         
          </div>
        </div>
      </div>
   
    </div>
  ) : (
    <div className="d-flex flex-column flex-space-between">
    <div
      className="d-flex flex-row flex-space-between"
      style={{ height: "60vh", marginBottom: "3%" }}
    >
      <div
        className="firsthalfprogressenergy"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <div style={{ height: "100%" }}>
          <DiversityMultiLocSingle brief={brief} timePeriodValues={timePeriodValues}/>
       
        </div>

        <div style={{ height: "0%" }}>
      
        </div>
      </div>
      <div
        className="secondhalfprogress"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <div style={{ height: "100%" }}>
          <DiversityMultiLocSingleAge brief={brief} timePeriodValues={timePeriodValues}/>
     
        </div>
        <div style={{ height: "0%" }}>
       
        </div>
      </div>
    </div>
  
  </div>
  );
};

export default DiversitySingleTimeMultLoc;
