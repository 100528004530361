import React from "react";
import { useState, useEffect } from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import NoNotification from "../../img/no-results.png";
import { NavLink } from "react-router-dom";
import "./recentactivity.css";
import "../ProgressBySector/sectorprogress.css"
const RecentActivity = ({fromDate,toDate,financialYearId}) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const firstName = currentUser?.first_name;
  const lastName = currentUser?.last_name;
  const [todaysActivities, setTodaysActivities] = useState([]);
  const todaysActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}todaysActivity`,
      {},
      {fromDate:fromDate,
        toDate:toDate,
        financialYearId: financialYearId },
      "GET"
    );
    if (isSuccess) {
      setTodaysActivities((data?.data).reverse());
    }
  };

  useEffect(() => {
    todaysActivity();
  }, [fromDate,toDate,financialYearId]);
  return (
    <div className="recentclass" style={{height:"100%"}}>
      <div style={{ marginBottom: "10px", marginTop:"1%" }}>
        <h5>Recent Activity</h5>
      </div>
      <div className="activity_section scroll-container" style={{height:"100%"}}>
        {todaysActivities?.length > 0 ? (
          todaysActivities?.map((notification) => (
            <div style={{display:"flex"}}>
              <div style={{
                alignItems:"center",
                justifyContent:"center"
                ,
                display:"flex"
              }}>
              <div className="circle-icon" style={{marinTop:"%"}}>
                  <div className="name_icon">{notification?.userName?.charAt(0)}</div>
                </div>

              </div>
               
                <div key={notification.id} style={{flexDirection:"column"}}className="d-flex flex-wrap mb-2 mt-2">
              
              <div className="d-flex align-items-center">
               
                <div className="name">{notification?.userName}</div>
                <div>
                <NavLink
                style={{marginTop:"0%", }}
                onClick={() => {
                  localStorage.setItem(
                    "reportingQuestion",
                    JSON.stringify([notification?.questionId])
                  );
                }}
                  to={{
                    pathname: "/reporting-modules/all-module",
                    state: {
                      reportingQuestion: [notification?.questionId],
                    },
                  }}
                >
                  <span  title={notification?.massage}>
                    {notification?.massage.length > 30
                      ? `${notification?.massage.slice(0, 50 )}.....`
                      : notification?.massage}
                  </span>
                </NavLink>{" "}
                </div>
              </div>
           
           
         

              <div className="d-flex" style={{marginLeft:"1%"}}>
                <span
                  style={{
                    marginLeft: "5px",
                    color: "#3F88A5",
                    fontSize: 14,
                    fontFamily: "Open Sans",
                    fontWeight: "400",
                    wordWrap: "break-word",
                    marginRight:"10px"
                  }}
                >
                  Time: {new Date(notification?.createdAt).toLocaleTimeString()}
                </span>

                <span  style={{
                  marginLeft: "5px",
                    color: "#3F88A5",
                    fontSize: 14,
                    fontFamily: "Open Sans",
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}>
                  Date: {new Date(notification?.createdAt).toLocaleDateString()}
                </span>
              </div>
            </div>
            </div>
           
          ))
        ) : (
          <div className="text-center w-100 h-75">
            {" "}
            <img
              src={NoNotification}
              alt="No Notification Here..."
              className="w-50 h-100"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentActivity;
