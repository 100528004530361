import img from "../../../img/no.png"

import React, { useEffect, useState } from "react";


const EnvBarComponent = ({
  type,
  totalConsumptionRenewable,
  totalConsumptionNonRenewable,
}) => {
  const [rowOptionsWithValues, setRowOptionsWithValues] = useState(() => {
    const options =
      type === "energy"
        ? [
            { value: totalConsumptionRenewable, option: "Renewable" },
            { value: totalConsumptionNonRenewable, option: "Non-Renewable" },
          ]
        : [
            { value: totalConsumptionRenewable, option: "Consumed" },
            { value: totalConsumptionNonRenewable, option: "Discharged" },
          ];

    return options;
  });
  useEffect(() => {
    if(totalConsumptionNonRenewable && totalConsumptionRenewable){
      setRowOptionsWithValues(() => {
        const options =
          type === "energy"
            ? [
                { value: totalConsumptionRenewable, option: "Renewable" },
                { value: totalConsumptionNonRenewable, option: "Non-Renewable" },
              ]
            : [
                { value: totalConsumptionRenewable, option: "Consumed" },
                { value: totalConsumptionNonRenewable, option: "Discharged" },
              ];
  
        return options;
      });

    }
   
  }, [totalConsumptionNonRenewable, totalConsumptionRenewable]);
  if(!totalConsumptionNonRenewable && !totalConsumptionRenewable){
    return(
      <div className='container'>
         <img
        src={img} // Replace with the actual image path or URL
        alt="No Data Available"
        style={{ width: "150px", height: "125px", display: "block", margin: "0 auto" }}
      />

      </div>
    )
  }


  const colors = [
    "#83BBD5",
    "#11546F",
    "#3ABEC7",
    "#3F88A5",
    "#88D29E",
    "#11546F",
  ]; // Colors for different water types

  const formatValue = (value) => {
    if (value >= 1e6) {
      return `${(value / 1e6).toFixed(0)}M`; // Format millions
    } else if (value >= 1e3) {
      return `${(value / 1e3).toFixed(0)}K`; // Format thousands
    } else {
      return Math.round(value); // Format normal numbers
    }
  };

  const formatNumberWithIndianCommas = (number) => {
    const x = number.toString().split(".");
    let num = x[0];
    let lastThree = num.slice(-3);
    const rest = num.slice(0, -3);

    if (rest !== "") {
      lastThree = "," + lastThree;
      const result = rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
      num = result + lastThree;
    } else {
      num = lastThree;
    }

    return x.length > 1 ? num + "." + x[1] : num; // Just format the number without manipulating the input string
  };

 

  // Helper function to adjust and round the total sum
  const adjustAndRoundTotalSum = (totalSum) => {
    if (totalSum < 1) {
      // Handle small decimals
      if (totalSum < 0.01) {
        // Round to nearest 0.005 for values below 0.01
        return Math.ceil(totalSum * 200) / 200; // (0.005 increments)
      } else if (totalSum < 0.1) {
        // Round to nearest 0.01 for values between 0.01 and 0.1
        return Math.ceil(totalSum * 100) / 100; // (0.01 increments)
      } else {
        // Round to nearest 0.5 or 1 for values between 0.1 and 1
        return Math.ceil(totalSum * 2) / 2; // (0.5 increments)
      }
    }
  
    const totalStr = totalSum.toString(); // Convert number to a string
const firstTwoDigits = parseInt(totalStr.slice(0, 2)); // Extract the first two digits
const magnitude = Math.pow(10, totalStr.length - 2); // Calculate the magnitude based on the number of digits

// Apply custom rounding logic based on the first two digits
if (firstTwoDigits > 75) {
    return 100 * magnitude; // Round to 100
} else if (firstTwoDigits > 50) {
    return 75 * magnitude; // Round to 75
} else if (firstTwoDigits > 25) {
    return 50 * magnitude; // Round to 50
} else if (firstTwoDigits > 10) {
    return 25 * magnitude; // Round to 25
} else {
    return 10 * magnitude; // Round to 10
}
  };

  const totalSum = rowOptionsWithValues.reduce(
    (sum, item) => sum + item.value,
    0
  );
  const adjustedTotalSum = adjustAndRoundTotalSum(totalSum);

  return (
    <div className="container">
      <div style={{ fontSize: "18px", fontWeight: 600, height: "10%" }}>
        {type === "energy"
          ? "Renewable & Non Renewable Energy"
          : "Water Consumed and Discharged"}
      </div>

      {/* Bar Labels Section */}
      <div className="renewable-bar-labels" style={{ marginTop: "5%" }}>
        <span style={{ fontSize: "11px" , fontWeight:"bold", fontSize:"12px"}}>0</span>
        <span style={{ fontSize: "11px" , fontWeight:"bold", fontSize:"12px"}}>
          {adjustedTotalSum === 0
            ? 1
            : formatValue(Math.round(adjustedTotalSum / 5))}
        </span>
        <span style={{ fontSize: "11px" , fontWeight:"bold", fontSize:"12px"}}>
          {adjustedTotalSum === 0
            ? 2
            : formatValue(Math.round((adjustedTotalSum / 5) * 2))}
        </span>
        <span style={{ fontSize: "11px" , fontWeight:"bold", fontSize:"12px"}}>
          {adjustedTotalSum === 0
            ? 3
            : formatValue(Math.round((adjustedTotalSum / 5) * 3))}
        </span>
        <span style={{ fontSize: "11px" , fontWeight:"bold", fontSize:"12px"}}>
          {adjustedTotalSum === 0
            ? 4
            : formatValue(Math.round((adjustedTotalSum / 5) * 4))}
        </span>
        <span style={{ fontSize: "11px" , fontWeight:"bold", fontSize:"12px"}}>
          {adjustedTotalSum === 0
            ? 5
            : formatValue(Math.round(adjustedTotalSum))}
        </span>
      </div>

      {/* Dotted Line for the Bar */}
      <div
        className="renewable-bar-dotted-line"
        style={{
          height: "1px",
          background: "dotted",
          marginBottom: "10px",
        }}
      ></div>

      {rowOptionsWithValues.length > 0 ? (
        <>
          {/* Bar Section */}
          <div
            style={{
              display: "flex",
              height: "30px",
              width: "100%",
              backgroundColor: "#e0e0e0",
              overflow: "hidden",
              marginBottom: "2px",
            }}
          >
            {rowOptionsWithValues.map((item, index) => {
              const widthPercentage = (item.value / adjustedTotalSum) * 100;
              return (
                <div
                  key={index}
                  title={`${item.option}: ${formatNumberWithIndianCommas(item.value)}`}
                  style={{
                    width: `${widthPercentage}%`,
                    backgroundColor: colors[index % colors.length],
                    display: item.value > 0 ? "flex" : "none", // Show only if value > 0
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></div>
              );
            })}
          </div>
          <div
            className="unit"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "3%",
              marginBottom: "5%",
            }}
          >
            <div
              style={{
                fontSize: "12px",
                fontWeight: 400,
                height: "100%",
                padding: "0%",
              }}
            >
              {type === "energy" ? "(in GJ)" : "(in KL)"}
            </div>
          </div>

          {/* Legend Section */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {rowOptionsWithValues.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  marginBottom: "1%",
                }}
              >
                <div
                  style={{
                    width: "15px",
                    borderRadius: "50%",
                    height: "15px",
                    backgroundColor: colors[index % colors.length],
                    marginRight: "5px",
                  }}
                />
                <span style={{ fontSize: "14px" }}>{item.option}</span>
              </div>
            ))}
            </div>
        </>
      ) : <>
      </>}
    </div>
  );
};

export default EnvBarComponent;
