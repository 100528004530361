import Swal from "sweetalert2";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./login.css";
import logo from "../../img/Zais_logo.png";
import "../sidebar/common.css";
import config from "../../config/config.json";
import { authenticationService } from "../../_services/authentication";
import { setStore } from "../../utils/UniversalFunction";
import env from "../../env";
import { BehaviorSubject } from "rxjs";
import { PermissionMenuContext } from "../../contextApi/permissionBasedMenuContext";
import { apiCall } from "../../_services/apiCall";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import Eye from "../../img/eye-icon.png";
import EyeCross from "../../img/eye-icon-cross.png";
import LoginImages from "../../img/login-image.jpg";
const baseURL = config.baseURL;
export default class signup extends Component {

  constructor(props) {
    super(props);
    // redirect to home if already logged in
    // if (authenticationService.currentUserValue) {
    //   this.props.history.push("/home");
    // }
    this.state = {
      user: {
        email: "",
        password: "",
        firstname: "",
        lastname: "",
        isLoggedIn: false,
        userInfo: {
          name: "",
          emailId: "",
        },
      },
      captchaIsVerified: false,
      type: "password",
      submitted: false,
      captchaVerification: false,
      loading: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVerification = this.handleVerification.bind(this);
    this.showHide = this.showHide.bind(this);
  }
  static contextType = PermissionMenuContext;
  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "password" ? "input" : "password",
    });
  }

  handleVerification() {
    this.setState({
      captchaIsVerified: true,
    });
  }

  // Logout Session and Update State
  logout = (response) => {
    let userInfo = {
      name: "",
      emailId: "",
    };
    this.setState({ userInfo, isLoggedIn: false });
  };

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  async handleSubmit(event) {
    this.setState({ loading: true })
    let currentUserSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    event.preventDefault();
    localStorage.removeItem("currentUser");
    currentUserSubject.next(null);

    this.setState({ submitted: true });
    const { user, captchaIsVerified } = this.state;
    let pushToRoute = "";
    // if (user.email && user.password && captchaIsVerified) {
    if (user.email && user.password) {
      let referenceData = window.localStorage.getItem("reference");
      const result = this.props.match.params?.token?.includes('&') ? this.props.match.params?.token?.split('&')[0] : this.props.match.params?.token;
      const { isSuccess, data } = await apiCall(
        `${config.AUTH_API_URL_COMPANY}${this.props.match.params?.token ? 'loginTrainee' : 'login'}`,
        {
          Portaltype: "COMPANY",
        },
        {
          email: user.email,
          password: user.password,
          token: result,
          reference: referenceData,
          userType: 'TRAINEE'
        },
        "POST"
      );
      this.setState({ loading: false })
      if (isSuccess) {
        Swal.fire({
          icon: "success",
          title: data.message,
          showConfirmButton: true,
          timer: 1000,
        });
        let setResponse = {};
        setResponse.data = data;
        // localStorage.setItem("token",localStorage.getItem("token"))
        localStorage.setItem("tmpcurrentUser", JSON.stringify(setResponse));
        // localStorage.setItem("menu", JSON.stringify(data?.menu))
        // localStorage.setItem("user_temp_id", JSON.stringify(setResponse?.data?.dataValues?.id))
        // localStorage.setItem("role", JSON.stringify(data?.currentRole))
        // localStorage.setItem("subscriptionAuth", JSON.stringify(200))
        localStorage.setItem(
          "token",
          JSON.stringify(data?.token).replaceAll('"', "")
        );
        localStorage.setItem(
          "user_temp_id",
          JSON.stringify(data?.user.dataValues?.company_id)
        );
        localStorage.setItem("role", "company");
        localStorage.setItem(
          "currentUser",
          JSON.stringify({
            ...data?.user?.dataValues,
            is_head: data?.is_head,
            hasValidPlan: data.hasValidPlan,
          })
        );
        localStorage.setItem("menu", JSON.stringify(data?.menu));
        authenticationService.currentUserSubject.next(setResponse);

        // localStorage.setItem("user_temp_email",JSON.stringify(response?.data?.))
        // if(response.data){
        //   this.props.history.push("/home")
        // }
        // localStorage.setItem("2faStatus", JSON.stringify(setSecondResponse));
        // localStorage.setItem(
        //   "subscriptionAuth",
        //   JSON.stringify(setSubsStatusCode)
        // );
        // let check2faStatus = response.data.twoFaStatus === 0;

        let subscriptionStatus = 200;
        let subscriptionPlanStatus =
          data?.hasValidPlan === "no plan" ? false : true;
        let supplier = data?.user_type_code;
        // if (check2faStatus) {
        //   pushToRoute = "/OTPVerify";
        // } else {

        // if (supplier == "supplier") {
        //   pushToRoute = "/settings";
        // }
        // else if (!subscriptionPlanStatus) {
        //   pushToRoute = "/#/Setting_Billing"
        // }
        // else if (subscriptionStatus === "200") {
        //   pushToRoute = "/settings";
        // }
        // }
        let rendarUrl = data?.menu[0]?.url;
        let pushToRoute = "/#/" + rendarUrl;
        let finalLink = "";
        finalLink = baseURL + pushToRoute;
        setTimeout(() => {
          window.location.href = finalLink;
        }, 1000);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Please fill all input",
        showConfirmButton: true,
        timer: 1000,
      });
    }
  }

  // componentDidMount() {
  //   if (authenticationService.currentUserValue) {
  //     this.props.history.push("/home");
  //   }
  //   this.videoRef.autoplay = true;
  // }

  render() {
    const { token } = this.props.match.params;
    const { user, submitted, type } = this.state;
    return (
      <div>
        <Row>
          <Col md={7} style={{ overflow: "hidden" }}>
            {/* <img src={LoginImages} alt="" /> */}
            <video
              ref={(ref) => (this.videoRef = ref)}
              autoPlay
              loop
              muted
              className="video-background"
            >
              <source
                src="https://riu-bucket.s3.ap-south-1.amazonaws.com/f6.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </Col>
          <Col md={5}>
            <section className="login">
              <div className="login_part">
                <div className="sing_log">
                  <div className="sing_one">
                    <img src={logo} alt="logo" className="w-50" />
                  </div>
                  <p className="text-center">
                    <b>Sign in to gain access</b>
                  </p>
                  <form name="form" onSubmit={this.handleSubmit}>
                    <div className="ster_form">
                      <div className="form_sign">
                        <div className="form-group">
                          <label className="st_name" htmlFor="name">
                            Email
                          </label>
                          <input
                            className="form-control mb-3 p-3"
                            type="text"
                            name="email"
                            placeholder="Enter Your Register Email-Id"
                            value={user.email}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="form_sign password-eye">
                        <div className="img-eye">
                          <span onClick={this.showHide}>
                            {this.state.type === "input" ? (
                              <img src={EyeCross} alt="" srcset="" />
                            ) : (
                              <img src={Eye} alt="" srcSet="" />
                            )}
                          </span>
                        </div>
                        <div className="form-group">
                          <label className="st_name" htmlFor="name">
                            Password
                          </label>
                          <input
                            className="form-control mb-3 p-3"
                            type={type}
                            name="password"
                            placeholder="Enter your password"
                            value={user.password}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="make_form">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="bacei">
                              <ReCAPTCHA
                                sitekey={env.GOOGLE_RECAPTCHA_SITE_KEY}
                                onChange={(e) => this.handleVerification(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div> */}

                    <div className="text-end">
                      <span>
                        Forgot Password?
                        <NavLink to="/ResetPass"> Reset </NavLink>
                      </span>
                    </div>
                    <Row className="my-4">
                      <Col>
                        {this.state?.loading ? (
                          <Button variant="info" className="w-100 p-3" disabled>
                            <Spinner animation="border" /> Logging...
                          </Button>
                        ) : user.email && user.password ? (
                          <Button
                            type="submit"
                            className="w-100 p-3"
                            variant="info"
                          >
                            <b>Login</b>
                          </Button>
                        ) : (
                          <Button
                            variant="secondary"
                            className="w-100 p-3"
                            disabled
                          >
                            <b>Login</b>
                          </Button>
                        )}
                      </Col>
                      {token && <div className='text-center mt-2'>
                        <h5>
                         Don't have an account?
                          <NavLink to={`/trainee_registration/${token}`} style={{ color: "#3F88A5", cursor: 'pointer' }}>
                            Sign Up
                          </NavLink>
                        </h5>
                      </div>}
                      {/* <div className="col-md-6">
                          <div className="global_link d-flex justify-content-center mx-0">
                            <NavLink
                              className="page_width page_save w-100"
                              to="/signup"
                            >
                              REGISTER
                            </NavLink>
                          </div>
                        </div> */}
                    </Row>

                    {/* <div style={{position: 'relative'}} >
                      <hr className="my-5" />
                      <span className="div-divider">OR</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-center my-4" style={{gap: '20px'}}>
                        <i className="fab fa-google g-icon"></i>
                        <i className="fab fa-microsoft g-icon"></i>
                      </div> */}
                  </form>
                </div>
              </div>
            </section>
          </Col>
        </Row>
      </div>
    );
  }
}
