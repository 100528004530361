import React from "react";
import TotalWasteGeneratedMukt from "./FrameworkOne/TotalWasteGeneratedMukt";
import TotalWasteDisposedMulti from "./FrameworkOne/TotalWasteDisposedMulti";
import TotalWasteRecoMulti from "./FrameworkOne/TotalWasteRecoMulti";
import WasteGenMultLoc from "./FrameworkFourtyEight/WasteGenMultLoc";
import WasteDispMultLoc from "./FrameworkFourtyEight/WasteDispMultLoc";

const SingleLocMultTime = ({
  companyFramework,
  sectorQuestionAnswerDataForGraph,
  timePeriods,
  graphData,
  matchedDataWaste,
  wasteDisposal,
  wasteRecovered,

  brief,
  locationOption,
  renewableEnergy,
  nonRenewableEnergy,
  keyTab,
  timePeriodValues,
}) => {
  return companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "80vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <TotalWasteGeneratedMukt
            timePeriods={timePeriods}
            locationOption={locationOption}
            timePeriodValues={timePeriodValues}
            title={"Total Waste Generated"}
            matchedDataWaste={matchedDataWaste}
          />
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "46%" }}>
            <TotalWasteDisposedMulti
              wasteDisposal={wasteDisposal}
              timePeriods={timePeriods}
            />
          </div>
          <div style={{ height:"48%" }}>
            <TotalWasteRecoMulti
              wasteRecovered={wasteRecovered}
              timePeriods={timePeriods}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "60vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <WasteGenMultLoc
              brief={brief}
              timePeriods={timePeriods}
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
            />
          </div>

          <div style={{ height: "0%" }}>
            {/* <ProductWiseStacked   timePeriodValues={timePeriodValues} locationOption={locationOption}  timePeriods={timePeriods} product={renewableEnergy}/> */}
          </div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <WasteDispMultLoc
              brief={brief}
              timePeriods={timePeriods}
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
            />
          </div>
          <div style={{ height: "0%" }}>
            {/* <ProductWiseStacked  timePeriodValues={timePeriodValues} locationOption={locationOption} timePeriods={timePeriods} product={nonRenewableEnergy} /> */}
          </div>
        </div>
      </div>
      {/* <div
      className="d-flex flex-row flex-space-between"
      style={{ height: "22vh", marginBottom: "3%" }}
    ></div> */}
    </div>
  );
};

export default SingleLocMultTime;
