import React from "react";
import MultipleBarWater from "./FrameworkOne/MultipleBarWater";
import WaterAreaComponent from "./FrameworkOne/WaterAreaComponent";
import WaterHorizontalBar from "./FrameworkFourtyEight/WaterHorizontalBar";
import WaterRecycledMulti from "./FrameworkFourtyEight/WaterRecycledMulti";
import WaterTreatedMulti from "./FrameworkFourtyEight/WaterTreatedMulti";


const WaterSingleLocMultTime = ({
  companyFramework,
  sectorQuestionAnswerDataForGraph,
  timePeriods,
  graphData,
  matchedDataWater,
  matchedWaterDis,
  brief,
  locationOption,
  keyTab,
  timePeriodValues,
}) => {
    
  return companyFramework.includes(1) ? 
  (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "60vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <MultipleBarWater locationOption={locationOption} title={"Water Consumption"} timePeriodValues={timePeriodValues} timePeriods={timePeriods} matchedDataWater={matchedDataWater} />
         
          </div>

          
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
          <MultipleBarWater locationOption={locationOption} title={"Water Discharged"} timePeriodValues={timePeriodValues} timePeriods={timePeriods} matchedDataWater={matchedWaterDis} />

       
          </div>
          
        </div>
      </div>
   
    </div>
  ) : (
    <div className="d-flex flex-column flex-space-between">
    <div
      className="d-flex flex-row flex-space-between"
      style={{ height: "60vh", marginBottom: "3%" }}
    >
      <div
        className="firsthalfprogressenergy"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <div style={{ height: "100%" }}>
          <WaterHorizontalBar brief={brief} locationOption={locationOption} timePeriods={timePeriods} timePeriodValues={timePeriodValues}/>
       
        </div>

        <div style={{ height: "0%" }}>
      
        </div>
      </div>
      <div
        className="secondhalfprogress"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <div style={{ height: "100%" }}>
          <WaterRecycledMulti brief={brief} locationOption={locationOption} timePeriods={timePeriods} timePeriodValues={timePeriodValues}/>
     
        </div>
        <div style={{ height: "0%" }}>
       
        </div>
      </div>
    </div>
    <div
      className="d-flex flex-row flex-space-between"
      style={{ height: "60vh", marginBottom: "3%" }}
    >
      <div
        className="firsthalfprogressenergy"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <div style={{ height: "100%" }}>
          <WaterTreatedMulti brief={brief} locationOption={locationOption} timePeriods={timePeriods} timePeriodValues={timePeriodValues}/>
       
        </div>

        <div style={{ height: "0%" }}>
      
        </div>
      </div>
      <div
        className="secondhalfprogress"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <div style={{ height: "100%" }}>
     
        </div>
        <div style={{ height: "0%" }}>
       
        </div>
      </div>
    </div>
   
  </div>
  );
};

export default WaterSingleLocMultTime;
