import React, { useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Dropdown,
  InputGroup,
  Modal,
  Button,
} from "react-bootstrap";
import { useState } from "react";
import { apiCall } from "../../../../_services/apiCall";
import { IoDownloadOutline } from "react-icons/io5";
import config from "../../../../config/config.json";
import swal from "sweetalert";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaFileDownload, FaTrash } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaUpload } from "react-icons/fa";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { DetailModal } from "./DetailModal";
const QualitativeComponent = ({
  sourceData,
  getAuditListing,
  sourceChange,
  questionType,
  dueDate,
  setUpdatedAt,
  apiData,
  // getReportingQuestions,
  selectedPeriod,
  title,
  auditItem,
  currentUserId,
  assignedToDetails,
  menu,
  savedAnswers,
  financeObject,
  answer,
  setAnswer,
  auditorId,
  answerId,
  item,
  toDate,
  fromDate,
  setAnsId,
}) => {
  const [remark, setRemark] = useState("");
  const [response, setResponse] = useState(answer?.response || "");
  const [note, setNote] = useState(answer?.note || [[""]]);
  const [answerIdReal, setAnswerIdReal] = useState();
  const [status, setStatus] = useState();
  const [responseError, setResponseError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isEditable, setIsEditable] = useState(true);
  const [localResponse, setLocalResponse] = useState(response);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showData, setShowData] = useState("");
  const [auditButton, setAuditButton] = useState(false);
  const [comment, setComment] = useState([[]]);
  const [commentModal, setCommentModal] = useState(false);
  const [comments, setComments] = useState("");
  const [file, setFile] = useState();
  const [target,setTarget]=useState();

  const [check, setCheck] = useState(true);

  const handleComment = (e) => {
    setComments(e.target.value);
  };

  const handleCommentChange = (value) => {
    setComment((prevState) => {
      const newProofDocument = [...prevState];

      if (!newProofDocument[0]) {
        newProofDocument[0] = [];
      }

      newProofDocument[0] = [...newProofDocument[0], value];

      return newProofDocument;
    });

    setAnswer((prevState) => {
      // Clone the current proofDocument
      const newProofDocument = [...prevState.comment];

      // If currentPage doesn't exist yet, initialize it as an empty array
      if (!newProofDocument[0]) {
        newProofDocument[0] = [];
      }

      // Update the specific page
      newProofDocument[0] = [...newProofDocument[0], value];

      // Return the updated state
      return {
        ...prevState,
        questionId: item?.questionId,
        moduleId: item?.moduleId,
        questionType: item?.questionType,
        frequency: item?.frequency,
        proofDocumentNote: newProofDocument,
      };
    });
  };

  const saveComment = () => {
    // Save the comment and close the modal
    handleCommentChange(comments);

    setCommentModal(false);
  };
  const itemHeight = "6vh"; // Adjust height as per design
  const inputStyle = {
    height: itemHeight,
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #ccc",

    backgroundColor: "white",
    borderRadius: "5px",
  };

  const cancelComment = () => {
    setCommentModal(false);
    setFileToDelete(null);
  };

  useEffect(() => {
    setLocalResponse(response);
  }, [response]);

  const handleDoubleClick = (data) => {
    if (data.trim() !== '') { // Check if data is not an empty string
      setShowData(data);
      setIsModalOpen(true);
    }
  };
  const [proofDocument, setProofDocument] = useState(
    answer?.proofDocument || [[]]
  );
  const handleRemarkChange = (e) => {
    const newRemark = e.target.value;
    setRemark(newRemark);
  };

  const initializeAnswer = () => {
    if (item && item.questionId && savedAnswers && savedAnswers.length) {
      // const matchedAnswer = savedAnswers.find(
      //   (savedAnswer) => savedAnswer.questionId == item.questionId
      // );
      let matchedAnswer;
      if (item?.frequency == "CUSTOM") {
        matchedAnswer = savedAnswers.find(
          (savedAnswer) =>
            savedAnswer.questionId == item.questionId &&
            savedAnswer.toDate == toDate &&
            savedAnswer.fromDate == fromDate  &&
            savedAnswer.sourceId == sourceChange
        );
        setAnswerIdReal(matchedAnswer?.id);
        setAnsId(matchedAnswer?.id);
        setUpdatedAt(matchedAnswer?.updatedAt)
      } else {
        matchedAnswer = savedAnswers.find(
          (savedAnswer) => savedAnswer.questionId == item.questionId
        );
        setAnswerIdReal(matchedAnswer?.id);
        setAnsId(matchedAnswer?.id);
        setUpdatedAt(matchedAnswer?.updatedAt)

      }
      if (matchedAnswer) {
        setAnswerIdReal(matchedAnswer?.id);
        setStatus(matchedAnswer?.status);
        setCheck(true);
        setResponse(matchedAnswer.answer || "");
        setUpdatedAt(matchedAnswer?.updatedAt)

        setNote((prevState) => {
          // Check if matchedAnswer.note is a string
          if (typeof matchedAnswer.note === "string") {
            return [[matchedAnswer.note]]; // Wrap the string in a 2D array
          }

          // Check if matchedAnswer.note is a 2D array
          if (
            Array.isArray(matchedAnswer.note) &&
            Array.isArray(matchedAnswer.note[0])
          ) {
            return matchedAnswer.note; // Use it as is
          }

          // If matchedAnswer.note is not a string or a 2D array, return an empty 2D array
          return [[""]];
        });
        if (Array.isArray(matchedAnswer.proofDocument)) {
          // Check if the first element is an array (indicating a 2D array)
          if (Array.isArray(matchedAnswer.proofDocument[0])) {
            setProofDocument(matchedAnswer.proofDocument);
          } else {
            // It's a 1D array, so wrap it in another array to make it 2D
            setProofDocument([matchedAnswer.proofDocument]);
          }
        } else {
          // If matchedAnswer.proofDocument is not an array, set it to an empty 2D array
          setProofDocument([[]]);
        }
        if (Array.isArray(matchedAnswer.proofDocumentNote)) {
          // Check if the first element is an array (indicating a 2D array)
          if (Array.isArray(matchedAnswer.proofDocumentNote[0])) {
            setComment(matchedAnswer.proofDocumentNote);
          } else {
            // It's a 1D array, so wrap it in another array to make it 2D
            setComment([matchedAnswer.proofDocumentNote]);
          }
        } else {
          // If matchedAnswer.proofDocument is not an array, set it to an empty 2D array
          setComment([[]]);
        }
        setAnswer((prevState) => ({
          ...prevState,
          questionId: item?.questionId,
          moduleId: item?.moduleId,
          answer: matchedAnswer.answer || "",
          response: matchedAnswer.answer || "",
          comment: matchedAnswer?.comment || [[]],

          note: (() => {
            // Check if matchedAnswer.note is a string
            if (typeof matchedAnswer.note === "string") {
              return [[matchedAnswer.note]]; // Wrap the string in a 2D array
            }

            // Check if matchedAnswer.note is a 2D array
            if (
              Array.isArray(matchedAnswer.note) &&
              Array.isArray(matchedAnswer.note[0])
            ) {
              return matchedAnswer.note; // Use it as is
            }

            // If matchedAnswer.note is not a string or a 2D array, return an empty 2D array
            return [[""]];
          })(),
          proofDocument: (() => {
            // Check if matchedAnswer.proofDocument is an array
            if (Array.isArray(matchedAnswer.proofDocument)) {
              // Check if the first element is an array (indicating a 2D array)
              if (Array.isArray(matchedAnswer.proofDocument[0])) {
                return matchedAnswer.proofDocument;
              } else {
                // It's a 1D array, so wrap it in another array to make it 2D
                return [matchedAnswer.proofDocument];
              }
            } else {
              // If matchedAnswer.proofDocument is not an array, return an empty 2D array
              return [[]];
            }
          })(),
        }));
      } else {
        setAnswer((prevState) => ({
          ...prevState,
          note: [[""]],
          questionId: item?.questionId,
          moduleId: item?.moduleId,
          questionType: item?.questionType,
          frequency: item?.frequency,
        }));
        setResponse("");
        setNote([[""]]);
        setProofDocument([[]]);
        setCheck(false)
      }
    }else{
      setResponse("");
      setCheck(false);
    }
  };


  const initializeTarget = () => {
    if (item && item.questionId && apiData && apiData.length) {
      // const matchedAnswer = savedAnswers.find(
      //   (savedAnswer) => savedAnswer.questionId == item.questionId
      // );
      let matchedAnswer;
      if (item?.frequency == "CUSTOM") {
        matchedAnswer = apiData.find(
          (savedAnswer) =>
            savedAnswer.questionId == item.questionId &&
            savedAnswer.toDate == toDate &&
            savedAnswer.fromDate == fromDate &&
            savedAnswer.sourceId == sourceChange
        );
        setTarget(matchedAnswer?.targetData);
      } else {
        matchedAnswer = apiData.find(
          (savedAnswer) => savedAnswer.questionId == item.questionId
        );
        setTarget(matchedAnswer?.targetData);
      }
      if (matchedAnswer) {
      }
    }
  };
  const handleAccept = async () => {
    const { isSuccess, error, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
      {},
      {
        questionId: item.questionId,
        answerId: answerIdReal,
        questionType: item.questionType,
        remark: remark,
        validation: "ACCEPTED",
        financialYearId: financeObject,
      },
      "POST"
    );

    if (isSuccess) {
      setRemark("");
      setAuditButton(true);
      getAuditListing();

      // swal({
      //   icon: "success",
      //   title: data.message,
      //   timer: 1000,
      // });
    }

    if (error) {
      // swal({
      //   icon: "error",
      //   title: data.message,
      //   timer: 1000,
      // });
    }
  };

  const handleReject = async () => {
    if (remark) {
      const { isSuccess, error, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
        {},
        {
          questionId: item.questionId,
          answerId: answerIdReal,
          questionType: item.questionType,
          remark: remark,
          validation: "REJECTED",
          financialYearId: financeObject,
        },
        "POST"
      );

      if (isSuccess) {
        setRemark("");
        getAuditListing();
      }

      if (error) {
        swal({
          icon: "error",
          title: data.message,
          timer: 1000,
        });
      }
    } else {
      swal({
        icon: "error",
        title: "Please enter remark",
        timer: 1000,
      });
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}saveAnswerReportingQuestion`,
      {},
      {
        ...answer,
      },
      "POST"
    );

    if (isSuccess) {
      // getReportingQuestions();
    }
  };

  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };

  const handleLocalResponseChange = (e) => {
    setLocalResponse(e.target.value);
    handleResponseChange(e); // to keep the original response state updated
  };

  const handleResponseChange = (e) => {
    const newResponse = e.target.value;
    if (newResponse === response) {
      setCheck(true);
    } else {
      setCheck(false);
    }
    if (title.toLowerCase().includes("email address")) {
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(newResponse) &&
        newResponse !== ""
      ) {
        setResponseError("Please enter a valid email address.");
      } else {
        setResponseError("");
        setResponse(newResponse);
      }
    } else if (title.toLowerCase().includes("website")) {
      const urlPattern =
        /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/\S*)?$/i;
      if (!urlPattern.test(newResponse) && newResponse !== "") {
        setResponseError("Please enter a valid website URL.");
      } else {
        setResponseError("");
        setResponse(newResponse);
      }
    } else {
      setResponseError("");
      setResponse(newResponse);
    }

    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      answer: newResponse,
    }));
  };

  const handleNoteChange = (e) => {
    const newNote = e.target.value;
    setNote([[newNote]]);
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      note: [[newNote]],
    }));
  };

  const uploadFile = async (files) => {
    const selectedFile = files.target.files[0];
    const timestamp = new Date().getTime(); // Generate a timestamp
    const fileName = `${timestamp}_${selectedFile.name}`;

    const formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("fileName", fileName);
    formdata.append("filePath", "yasil/");

    const requestOptions = {
      header: {
        "Content-Type": "multipart/form-data", // Set the Content-Type header
      },
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${
          config.AUTH_API_URL_COMPANY
        }uploadFile?current_role=${localStorage.getItem("role")}`,
        requestOptions
      );
      const result = await response.text();
      const url = JSON.parse(result);

      // Append the new file URL to the list of proofDocument
      setProofDocument((prevState) => {
        const newProofDocument = [...prevState];

        if (!newProofDocument[0]) {
          newProofDocument[0] = [];
        }

        newProofDocument[0] = [...newProofDocument[0], url.url];

        return newProofDocument;
      });
      setAnswer((prevState) => {
        const newProofDocument = [...prevState.proofDocument];

        // If currentPage doesn't exist yet, initialize it as an empty array
        if (!newProofDocument[0]) {
          newProofDocument[0] = [];
        }

        // Update the specific page
        newProofDocument[0] = [...newProofDocument[0], url.url];

        return {
          ...prevState,
          proofDocument: newProofDocument,
        };
      });
      setFile(`${timestamp}_${selectedFile.name}`);
      setCommentModal(true);
    } catch (error) {
      if (error) {
        swal({
          icon: "error",
          title: error,
          timer: 1000,
        });
      }
    }
  };

  useEffect(() => {
    initializeAnswer();
  }, [savedAnswers, item, toDate, fromDate]);

  const confirmDelete = () => {
    setProofDocument((prevState) => {
      // Clone the current proofDocument array
      const newProofDocument = [...prevState];

      // Update the specific page by filtering out the fileToDelete
      newProofDocument[0] = newProofDocument[0].filter(
        (url) => url !== fileToDelete
      );

      return newProofDocument;
    });
    setShowModal(false);
    setFileToDelete(null); // Clear the file to delete
  };

  // Cancel deletion
  const cancelDelete = () => {
    setShowModal(false);
    setFileToDelete(null);
  };
  const [address, setAddress] = useState(null);
  const handleChange = (newAddress) => {
    const newResponse = newAddress;
    if (newResponse === response) {
      setCheck(true);
    } else {
      setCheck(false);
    }
    setAddress(newAddress);
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      answer: newAddress,
    }));
    setResponse(newAddress);
  };

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCnheSiSU_ytNqSUXH4Qi9PnrKHAU4MLmQ&libraries=places`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
      script.onload = () => {
        // Initialize PlacesAutocomplete after the script is loaded
        console.log("Google Maps API script loaded successfully.");
      };
    };
    loadGoogleMapsScript();
  }, []);
  function getComponent(components, type) {
    return (
      components.find((component) => component.types.includes(type))
        ?.long_name || ""
    );
  }
  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      const addressComponents = results[0].address_components;
      setAddress(addressComponents);
      const streetNumber = getComponent(addressComponents, "street_number");
      const route = getComponent(addressComponents, "route");
      const city = getComponent(addressComponents, "locality");
      const state = getComponent(
        addressComponents,
        "administrative_area_level_1"
      );
      const zipcode = getComponent(addressComponents, "postal_code");

      // Combine the components into a single address string
      const completeAddress = `${
        streetNumber ? streetNumber + " " : ""
      }${route}, ${city}, ${state} ${zipcode}`;
      setResponse(completeAddress);
      const newResponse = completeAddress;
      if (newResponse === response) {
        setCheck(true);
      } else {
        setCheck(false);
      }
      setAnswer((prevAnswer) => ({
        ...prevAnswer,
        answer: completeAddress,
      }));
      setAddress(completeAddress);
    } catch (error) {
      console.error("Error selecting location", error);
    }
  };

  const handleDeleteClick = (url) => {
    setFileToDelete(url);
    setShowModal(true);
  };

  const handleFileDownload = (url) => {
    const replacedUrl = url.replace(
      "https://riu-bucket.s3.ap-south-1.amazonaws.com",
      "https://copyadatafromawstoazure.blob.core.windows.net/uploads"
    );
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; // Opens in a new tab
    link.download = url.split("/").pop();
    document.body.appendChild(link); // Append to body
    link.click();
    document.body.removeChild(link); // Clean up
  };

  const filteredDocs = (proofDocument[0] || []).filter(
    (doc) => doc && typeof doc === "string" && doc.trim() !== ""
  );

  const filteredComments = Array.isArray(comment[0])
    ? comment[0].filter(
        (c, index) =>
          index < filteredDocs.length && (typeof c === "string" || c === "")
      )
    : [];
  return (
    <Form>
      {/* First Row with Response Field */}
      <Row>
        <Col md={12}>
          <Form.Group controlId="formInput11">
            <Form.Label className="custom-label">Response</Form.Label>
            {title
              .toLowerCase()
              .includes("when was the company incorporateds?") ? (
              <DatePicker
                selected={response ? new Date(response) : null}
                onChange={(date) => {
                  setResponse(date ? date.toISOString() : "");
                  setAnswer((prevAnswer) => ({
                    ...prevAnswer,
                    answer: date ? date.toISOString() : "",
                  }));
                }}
                className="form-control"
                dateFormat="yyyy-MM-dd"
              />
            ) : title
                .toLowerCase()
                .includes("what is the financial year for this reports?") ? (
              <div>
                <DatePicker
                  selected={response ? new Date(response) : null}
                  onChange={(date) => {
                    setResponse(date ? date.getFullYear().toString() : "");
                    setAnswer((prevAnswer) => ({
                      ...prevAnswer,
                      answer: date ? date.getFullYear().toString() : "",
                    }));
                  }}
                  className="form-control"
                  showYearPicker
                  dateFormat="yyyy"
                />
              </div>
            ) : title.toLowerCase().includes("paid-up capital?") ? (
              <>
                <InputGroup>
                  <InputGroup.Text>₹</InputGroup.Text>
                  <Form.Control
                    style={{backgroundColor:"#Dfebef" }}
                    type="text"
                    value={response || ""}
                    onChange={handleResponseChange}
                    readOnly={
                      menu === "audit" ||
                      (assignedToDetails?.assignedTo?.length > 0 &&
                        !assignedToDetails?.assignedTo?.some(
                          (id) => parseInt(id, 10) === currentUserId
                        )) ||
                      (check && isEditable) ||
                      (assignedToDetails?.dueDate &&
                        new Date(assignedToDetails.dueDate).setHours(
                          0,
                          0,
                          0,
                          0
                        ) < new Date().setHours(0, 0, 0, 0))
                    }
                  />
                </InputGroup>
                {response &&
                  check &&
                  assignedToDetails?.assignedTo?.length > 0 &&
                  !assignedToDetails?.assignedTo?.some(
                    (id) => parseInt(id, 10) !== currentUserId
                  ) && (
                    <div className="qua-icon">
                      <FaEdit
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          height: "20px",
                          width: "20px",
                          color: isEditable ? "black" : "#BFD7E0",
                        }}
                        onClick={handleEditClick}
                      />
                    </div>
                  )}
              </>
            ) : title
                .toLowerCase()
                .includes("address of the registered offices?") ? (
              <>
                <PlacesAutocomplete
                  value={address || response}
                  onChange={handleChange}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({
                          placeholder: "Type your location... *",
                          className: "location-search-input w-100 mb-2",
                        })}
                        required
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => (
                          <div
                            {...getSuggestionItemProps(suggestion)}
                            key={suggestion.placeId}
                          >
                            {suggestion.description}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                {response &&
                  check &&
                  assignedToDetails?.assignedTo?.length > 0 &&
                  !assignedToDetails?.assignedTo?.some(
                    (id) => parseInt(id, 10) !== currentUserId
                  ) && (
                    <div className="qua-icon">
                      <FaEdit
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          height: "20px",
                          width: "20px",
                          color: isEditable ? "black" : "#BFD7E0",
                        }}
                        onClick={handleEditClick}
                      />
                    </div>
                  )}
              </>
            ) : title
                .toLowerCase()
                .includes("address of the corporate offices?") ? (
              <>
                <PlacesAutocomplete
                  value={address || response}
                  onChange={handleChange}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({
                          placeholder: "Type your location... *",
                          className: "location-search-input w-100 mb-2",
                        })}
                        required
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => (
                          <div
                            {...getSuggestionItemProps(suggestion)}
                            key={suggestion.placeId}
                          >
                            {suggestion.description}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>

                {response &&
                  check &&
                  assignedToDetails?.assignedTo?.length > 0 &&
                  !assignedToDetails?.assignedTo?.some(
                    (id) => parseInt(id, 10) !== currentUserId
                  ) && (
                    <div className="qua-icon">
                      <FaEdit
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          height: "20px",
                          width: "20px",
                          color: isEditable ? "black" : "#BFD7E0",
                        }}
                        onClick={handleEditClick}
                      />
                    </div>
                  )}
              </>
            ) : (
              <InputGroup>
                <Form.Control
                  style={{backgroundColor:"#Dfebef" }}
                  type="text"
                  as="textarea"
                  value={localResponse || ""}
                  onChange={handleLocalResponseChange}
                  onDoubleClick={() => handleDoubleClick(localResponse)}
                  readOnly={
                    menu === "audit" ||
                    (assignedToDetails?.assignedTo?.length > 0 &&
                      !assignedToDetails?.assignedTo?.some(
                        (id) => parseInt(id, 10) === currentUserId
                      )) ||
                    (check && isEditable) ||
                    (assignedToDetails?.dueDate &&
                      new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                        new Date().setHours(0, 0, 0, 0))
                  }
                />
                {response &&
                  check &&
                  assignedToDetails?.assignedTo?.length > 0 &&
                  !assignedToDetails?.assignedTo?.some(
                    (id) => parseInt(id, 10) !== currentUserId
                  ) && (
                    <div className="qua-icon">
                      <FaEdit
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          height: "20px",
                          width: "20px",
                          color: isEditable ? "black" : "#BFD7E0",
                        }}
                        onClick={handleEditClick}
                      />
                    </div>
                  )}
              </InputGroup>
            )}

            {responseError && <p style={{ color: "red" }}>{responseError}</p>}
          </Form.Group>
        </Col>
      </Row>

      {/* Second Row with File Upload */}
      <Row>
        <Col md={6}>
          {menu !== "audit" && (
            <Form.Group
              controlId="formFile"
              className="custom-file-upload"
              style={{ marginBottom: "5%" }}
            >
              <Form.Label className="custom-label">
                Upload Attachment
              </Form.Label>
              <div className="file-upload-wrapper">
                <label className="upload-btn">
                  <div style={{ height: "12px", width: "12px" }}>
                    <FaUpload height={"12px"} width={"12px"} />
                  </div>
                  <span>Upload a file</span>
                  <Form.Control
                    type="file"
                    disabled={
                      menu === "audit" ||
                      (assignedToDetails?.assignedTo?.length > 0 &&
                        !assignedToDetails?.assignedTo?.some(
                          (id) => parseInt(id, 10) === currentUserId
                        )) ||
                      (check && isEditable) ||
                      (assignedToDetails?.dueDate &&
                        new Date(assignedToDetails.dueDate).setHours(
                          0,
                          0,
                          0,
                          0
                        ) < new Date().setHours(0, 0, 0, 0))
                    }
                    style={{ display: "none" }} // Hide the default file input
                    onChange={(e) => uploadFile(e)}
                  />
                </label>
              </div>
            </Form.Group>
          )}
          {/* Display Uploaded Files */}
          <>
            {filteredDocs && filteredDocs.length > 0 && (
              <Row
                style={{
                  backgroundColor: "#DFEBEF",
                  width: "100%",
                  // padding: "20px",
                  borderRadius: "8px",
                  marginLeft: "0px",
                  marginTop: menu !== "audit" ? "0%" : "3%",
                }}
              >
                {filteredDocs && filteredDocs.length > 0 && (
                  <Row
                    style={{
                      backgroundColor: "#DFEBEF",
                      width: "100%",
                      padding: "20px",
                      borderRadius: "8px",
                      marginLeft: "0px",
                    }}
                  >
                    {filteredDocs.map((url, index) => {
                      const docSegments = url.split("/");
                      const docFileName =
                        docSegments.length > 1
                          ? docSegments[docSegments.length - 2] ===
                            docSegments[docSegments.length - 1]
                            ? docSegments[docSegments.length - 1]
                            : docSegments[docSegments.length - 1]
                          : url;

                      let commentText = "";
                      if (
                        filteredComments.length > 0 &&
                        index < filteredComments.length
                      ) {
                        commentText =
                          filteredComments[index] !== 0
                            ? filteredComments[index]
                            : ""; // Check if commentText is 0 and replace with an empty string if true
                      } else {
                        console.log(
                          "No valid comment found for the given index."
                        );
                      }

                      return (
                        <Row key={index} style={{}}>
                          <Col md={6}>
                            <div style={inputStyle}>
                              <span
                                style={{
                                  marginLeft: 10,
                                  marginRight: 10,
                                  wordBreak: "break-all",
                                  flexGrow: 1,
                                  fontSize: "12px",
                                  maxWidth: "70%",
                                }}
                                title={docFileName}
                              >
                                {decodeURIComponent(docFileName).slice(0, 50)}
                              </span>

                              <IoDownloadOutline
                                style={{
                                  marginLeft: 10,
                                  cursor: "pointer",
                                  height: "20px",
                                  width: "20px",
                                  color: "black",
                                }}
                                onClick={() => handleFileDownload(url)}
                                title="Download File"
                              />

                              {!(
                                menu === "audit" ||
                                (assignedToDetails?.assignedTo?.length > 0 &&
                                  !assignedToDetails?.assignedTo?.some(
                                    (id) => parseInt(id, 10) === currentUserId
                                  ))
                              ) && (
                                <RiDeleteBinLine
                                  style={{
                                    cursor: "pointer",
                                    height: "20px",
                                    width: "20px",
                                    color: "black",
                                    marginLeft: "1rem",
                                  }}
                                  onClick={() => handleDeleteClick(url)}
                                  title="Remove File"
                                />
                              )}
                            </div>
                          </Col>

                          <Col md={6} style={{ marginLeft: "1.8rem" }}>
                            <div style={inputStyle}>
                              <Form.Control
                                type="text"
                                defaultValue={commentText}
                                readOnly={
                                  menu === "audit" ||
                                  (assignedToDetails?.assignedTo?.length > 0 &&
                                    !assignedToDetails?.assignedTo?.some(
                                      (id) => parseInt(id, 10) === currentUserId
                                    )) ||
                                  (check && isEditable) ||
                                  (assignedToDetails?.dueDate &&
                                    new Date(
                                      assignedToDetails.dueDate
                                    ).setHours(0, 0, 0, 0) <
                                      new Date().setHours(0, 0, 0, 0))
                                }
                                placeholder="No Comment"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  border: "none",
                                  borderRadius: "5px",
                                  border: "1.5px solid #3F88A5",
                                  borderColor: "#3F88A5",
                                  background: "#DFEBEF",
                                  backgroundColor: "#DFEBEF",
                                  paddingLeft: "10px",
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </Row>
                )}
              </Row>
            )}

            {/* Confirmation Modal */}
            <DetailModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              data={showData}
            />
            <Modal show={showModal} onHide={cancelDelete} centered>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete this file?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={cancelDelete}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={confirmDelete}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        </Col>
      </Row>

      {/* Conditional Rendering for Note Field */}
      {item?.applicableNote === 1 && (
        <Row>
          <Col md={12}>
            <Form.Group controlId="formInput12">
              <Form.Label className="custom-label">Note</Form.Label>
              <Form.Control
                style={{
                 backgroundColor:"#Dfebef",
                  resize: "horizontal",
                  overflow: "auto",
                }}
                type="text"
                as="textarea"
                value={note[0][0]}
                onDoubleClick={() => handleDoubleClick(note)}
                onChange={handleNoteChange}
                readOnly={
                  menu === "audit" ||
                  (assignedToDetails?.assignedTo?.length > 0 &&
                    !assignedToDetails?.assignedTo?.some(
                      (id) => parseInt(id, 10) === currentUserId
                    )) ||
                  (check && isEditable)
                }
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {menu === "audit" && (
        <Col md={8}>
          <Form.Group controlId="formInput12">
            <Form.Label className="custom-label">Remark</Form.Label>
            <Form.Control
              style={{backgroundColor:"#Dfebef" }}
              type="text"
              value={remark}
              onChange={handleRemarkChange}
            />
          </Form.Group>
        </Col>
      )}

      {/* Button Section */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* Placeholder button */}
        {/* <button
          className="btn btn-secondary"
          style={{
            borderColor: "white",
            backgroundColor: "transparent",
            padding: "1%",
            paddingLeft: "1.5%",
            paddingRight: "1.5%",
          }}
        ></button> */}

        {/* Conditional rendering for buttons */}
        <div
          className="d-flex"
          style={{ width: "30%", justifyContent: "space-between" }}
        >
          {/* <button
            className="btn btn-primary me-5x"
            style={{
              borderColor: "white",
              backgroundColor: "transparent",
              padding: "2%",
              paddingLeft: "2%",
              paddingRight: "2%",
              paddingTop: "3%",
              paddingBottom: "3%",
            }}
          >
            Save the answer
          </button> */}

          {menu === "audit" ? (
            <>
              {auditItem &&
                auditItem?.matchingAuditors.find(
                  (d) => d.answerId == answerIdReal
                )?.auditerId == currentUserId &&
                answerIdReal && (
                  <>
                    {auditItem?.matchingAuditors.find(
                      (d) => d.answerId == answerIdReal
                    )?.remark == null ||
                    auditItem?.matchingAuditors
                      .find((d) => d.answerId == answerIdReal)
                      ?.remark?.every(
                        (remark) => remark.id != currentUserId
                      ) ||  auditItem.matchingAuditors?.some(
                        (auditor) => Number(auditor?.auditerId) === Number(currentUserId)
                      )? (
                      <>
                        <button
                          className="btn"
                          onClick={handleAccept}
                          type="button"
                          style={{
                            borderColor: "white",
                            backgroundColor: "#3F88A5",
                            color: "white",
                            padding: "2%",
                            paddingLeft: "10%",
                            paddingRight: "10%",
                          }}
                        >
                          Accept
                        </button>
                        <button
                          className="btn"
                          onClick={handleReject}
                          type="button"
                          style={{
                            borderColor: "white",
                            backgroundColor: "#3F88A5",
                            padding: "1%",
                            color: "white",
                            paddingLeft: "10%",
                            paddingRight: "10%",
                          }}
                        >
                          Reject
                        </button>
                      </>
                    ) : status === "ACCEPTED" || status === "REJECTED" ? (
                      <p>This answer has been audited.</p>
                    ) : null}
                  </>
                )}
            </>
          ) : (
            assignedToDetails?.assignedTo?.length > 0 &&
            assignedToDetails?.assignedTo?.some(
              (id) => parseInt(id, 10) === currentUserId
            ) && (
              <button
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={
                  (item?.frequency === "CUSTOM" &&
                    (!selectedPeriod ||
                      !localResponse ||
                      localResponse === response)) ||
                  (item?.frequency !== "CUSTOM" &&
                    (!response || !localResponse))
                }
                style={{
                  borderColor: "white",
                  backgroundColor: "#3F88A5",
                  padding: "2%",
                  paddingLeft: "10%",
                  paddingRight: "10%",
                }}
              >
                Submit
              </button>
            )
          )}
        </div>
      </div>
      <Modal show={commentModal} onHide={cancelComment} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Add a comment for file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formComment">
            <div>{file}</div>
            <Form.Label>Please provide a comment (optional):</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={comments}
              onChange={(e) => handleComment(e)}
              placeholder="Enter your comment here"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelDelete}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveComment}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export default QualitativeComponent;
