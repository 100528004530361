// import React from 'react';
// import Chart from 'react-apexcharts';
// // import ApexCharts from 'apexcharts';
// import { useState } from 'react';
// import ReactApexChart from 'react-apexcharts';
// import { Col, Row, Form} from 'react-bootstrap';

// const ScopeMultiLocComparison = () => {
//   const location =2

//   const [view, setView] = useState('time');
//   const [selection, setSelection] = useState('Q1');

//   // Sample data
//   const locations = ['Location 1', 'Location 2', 'Location 3', 'Location 4', 'Location 5'];
//   const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];

//   const data = {
//     time: {
//       Q1: [10, 20, 30, 40, 50],
//       Q2: [15, 25, 35, 45, 55],
//       Q3: [20, 30, 40, 50, 60],
//       Q4: [25, 35, 45, 55, 65]
//     },
//     location: {
//       'Location 1': [10, 15, 20, 25],
//       'Location 2': [20, 25, 30, 35],
//       'Location 3': [30, 35, 40, 45],
//       'Location 4': [40, 45, 50, 55],
//       'Location 5': [50, 55, 60, 65]
//     }
//   };

//   const handleViewChange = (e) => {
//     setView(e.target.value);
//     setSelection(view === 'time' ? quarters[0] : locations[0]); // Reset selection based on view
//   };

//   const handleSelectionChange = (e) => {
//     setSelection(e.target.value);
//   };

//   const chartOptions = {
//     chart: {
//       type: 'bar',
//       stacked: true
//     },
//     plotOptions: {
//       bar: {
//         horizontal: true,
//         dataLabels: {
//           position: 'top'
//         }
//       }
//     },
//     dataLabels: {
//       enabled: true
//     },
//     xaxis: {
//       categories: view === 'time' ? quarters : locations
//       // categories: quarters
//     },
//     yaxis: {
//       title: {
//         text: 'Water Withdrawal'
//       }
//     },
//     legend: {
//       position: 'top'
//     }
//   };

//   const chartSeries = [
//     {
//       name: view === 'time' ? 'Quarters' : 'Location',
//       data: view === 'location'
//         ? data.time[selection]
//         : data.location[selection]
//     }
//   ];

//   const innerOptions = {
//     chart: {
//       type: "donut",
//       height: 350,
//       offsetY: 0,
//       offsetX: 0,
//     },
//     plotOptions: {
//       pie: {
//         donut: {
//           size: "60%", // Size of the outer donut (controls the width)
//         },
//       },

//       radialBar: {
//         hollow: {
//           size: "100%", // Size of the hollow area to create a thick donut
//         },
//         track: {
//           background: "#e0e0e0",
//           strokeWidth: "20%",
//         },
//         dataLabels: {
//             enabled:false,
//           name: {
//             fontSize: "22px",
//             color: "#000",
//             offsetY: -10,
//           },
//           value: {
//             fontSize: "16px",
//             color: "#000",
//             offsetY: 10,
//           },
//           total: {
//             show: false,
//             label: "Total",
//             formatter: function (w) {
//               return 100;
//             },
//           },
//         },
//       },
//     },
//     series: [70, 20, 10], // Distribution data
//     labels: ["Apples", "Oranges", "Bananas"],
//     colors: ["#FF4560", "#00E396", "#008FFB"],
//     legend: {
//       show: false, // Hides the legend for the inner donut
//     }, //
//   };

//   // Outer donut chart options
//   const outerOptions = {
//     chart: {
//       type: "donut",
//       height: 350,
//       offsetY: 0,
//       offsetX: 0,
//     },
//     plotOptions: {
//       pie: {
//         donut: {
//           size: "90%", // Size of the outer donut (controls the width)
//         },
//       },
      
//       radialBar: {
//         hollow: {
//           size: "20%", // Size of the hollow area to create a thinner donut
//         },
//         track: {
//           background: "#f0f0f0",
//           strokeWidth: "50%",
//         },
//         dataLabels: {
//             enabled:false,
//           show: false, // Hide data labels for the outer donut
//         },
//       },
//     },
//     series: [70, 20, 10], // Dummy series to create the outer donut
//     labels: ["Apples", "Oranges", "Bananas"],
//     colors: ["#FF4560", "#00E396", "#008FFB"],
//     dataLabels: {
//       enabled: false, // Hides the numbers showing on the tracks
//     },
//     legend: {
//       show: true, // Show the legend
//       position: "bottom", // Position the legend at the bottom
//       horizontalAlign: "center", // Center-align the legend items
//       fontSize: "14px", // Set font size for legend text
//       markers: {
//         width: 10, // Set the width of legend markers
//         height: 10, // Set the height of legend markers
//       },
//       itemMargin: {
//         horizontal: 10, // Space between legend items horizontally
//         vertical: 5, // Space between legend items vertically
//       },
//     },
//   };
    

//   return (
//     <div style={containerStyle}>
//       <div style={headingStyle}>Scope 1 and 2 comparison</div>
      
//       {location===1 && <div
//         className="chart-container"
//         style={{ marginTop: "-2%", height: "80%", position: "relative",marginTop:"5%",marginLeft:"15%" }}
//       >
//         <div id="outer-donut" style={{ position: "absolute", top: 0, left: 0, height:"100%"}}>
//           <ReactApexChart
//             options={outerOptions}
//             series={outerOptions.series}
//             type="donut"
//             height={"100%"}
//             width={"100%"}
//           />
//         </div>
//         <div
//           id="inner-donut"
//           style={{ position: "absolute", top: 15, left: 37, height:"100%"}}
//         >
//           <ReactApexChart
//             options={innerOptions}
//             series={innerOptions.series}
//             type="donut"
//             height={"75%"}
//             width={"75%"}
//           />
//         </div>
//       </div>}
//       {
//         location >=1 && 
//         <div>
//          <div className="radio-group horizontal" style={
//           {
//             display:"flex", flexDirection:"row"
//           }
//          }>
//         <Form.Check
//           style={{marginRight:"5%"}}
//           type="radio"
//           label="Time"
//           value="time"
//           checked={view === 'time'}
//           onChange={handleViewChange}
//         />
//         <Form.Check
//           type="radio"
//           label="Location"
//           value="location"
//           checked={view === 'location'}
//           onChange={handleViewChange}
//         />
//       </div>

//       <div className="radio-group horizontal" style={
//           {
//             marginTop:"3%",
//             display:"flex", flexDirection:"row",
//             width:"100%",overflow:"auto"
//           }
//          }>
//         {view === 'time'
//           ? locations.map(q => (
//             <Form.Check
//               key={q}
//               style={{marginRight:"5%"}}
//               type="radio"
//               label={q}
//               value={q}
//               checked={selection === q}
//               onChange={handleSelectionChange}
//             />
//           ))
//           : quarters.map(loc => (
//             <Form.Check
//               key={loc}
//               type="radio"
//               label={loc}
//               style={{marginRight:"5%"}}
//               value={loc}
//               checked={selection === loc}
//               onChange={handleSelectionChange}
//             />
//           ))}
//       </div>
  
//         <ReactApexChart
//           options={chartOptions}
//           series={chartSeries}
//           type="bar"
//           height={"100%"}
//         />
//       </div>
//       }

//     </div>
//   );
// };

// const containerStyle = {
//   backgroundColor: 'white',
//   borderRadius: '15px',
//   width: '100%',
//   height: '100%',
//   padding: '20px',
//   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
// };

// const headingStyle = {
//   fontSize: '18px',
//   fontWeight: 'bold',
//   height:"5%",
//   marginBottom: '20px',
//   textAlign: 'left',
// };

// const chartContainerStyle = {
//   display: 'flex',
//   justifyContent: 'center',
//   height:"85%"
// };

// export default ScopeMultiLocComparison;

import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const colorPalette = [
  "#C6CB8D",
  "#858862",
  "#ABC4B2",
  "#587B87",
  "#9CDFE3",
  "#FF8C33",
  "#33FFF4",
  "#9DFF33",
  "#FF3333",
  "#3377FF",
  "#FF7F50",
  "#FFD700",
  "#8A2BE2",
  "#D2691E",
  "#00FA9A",
];

const ScopeMultiLocComparison = ({  locationOption,
  timePeriods,
  financialYearId,
  graphData}) => {
    const [locations, setLocations] = useState([""]);
    const [quarters, setQuarters] = useState([""]);
    const [view, setView] = useState("time");
    const [selection, setSelection] = useState("M1");
    const [colors, setColors] = useState([]);
    const [chartOptions,setChartOptions]=useState( {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
      },
  
      xaxis: {
        categories:
          selection === 'location'
            ? locations
            : quarters,
     
      },
      yaxis: {
     
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: false, // Hides the legend
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      colors: ['#3F88A5', '#587B87', '#8DA7BE', '#BCCCDC'], // Customize the colors
    })
  const [selectedOption, setSelectedOption] = useState('location');
  const [selectedCategory, setSelectedCategory] = useState('Q1');
  const [data, setData] = useState({
    time: {
      "Location 1": {
        groundWater: [2000, 1800, 1600, 1400],
        normalWater: [1500, 1300, 1200, 1000],
        hardWater: [500, 600, 700, 800],
      },
      "Location 2": {
        groundWater: [1900, 1700, 1500, 1300],
        normalWater: [1600, 1400, 1200, 1100],
        hardWater: [600, 700, 800, 900],
      },
      "Location 3": {
        groundWater: [1800, 1600, 1400, 1200],
        normalWater: [1700, 1500, 1300, 1100],
        hardWater: [700, 800, 900, 1000],
      },
      "Location 4": {
        groundWater: [1700, 1500, 1300, 1100],
        normalWater: [1800, 1600, 1400, 1200],
        hardWater: [800, 900, 1000, 1100],
      },
      "Location 5": {
        groundWater: [1600, 1400, 1200, 1000],
        normalWater: [1900, 1700, 1500, 1300],
        hardWater: [900, 1000, 1100, 1200],
      },
    },
    location: {
      Q1: {
        groundWater: [2000, 1500, 1000, 1200, 1300],
        normalWater: [1500, 1200, 1100, 1000, 1400],
        hardWater: [500, 800, 900, 800, 1200],
      },
      Q2: {
        groundWater: [1800, 1600, 1100, 1200, 1300],
        normalWater: [1600, 1300, 1200, 1100, 1400],
        hardWater: [600, 700, 800, 600, 1100],
      },
      Q3: {
        groundWater: [1900, 1700, 1200, 1300, 1400],
        normalWater: [1700, 1400, 1300, 1200, 1500],
        hardWater: [700, 800, 900, 700, 1300],
      },
      Q4: {
        groundWater: [2000, 1800, 1300, 1400, 1500],
        normalWater: [1800, 1500, 1400, 1300, 1600],
        hardWater: [800, 900, 1000, 800, 1400],
      },
    },
  });

  const categories = selectedOption === 'time' ? locations : quarters;

  const options = 
  {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
    },

    xaxis: {
      categories:
        selectedOption === 'location'
          ? locations
          : quarters,
     
    },
    yaxis: {
      title: {
        text: undefined, // Hide the Y axis title as shown in the design
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    colors: ['#3F88A5', '#587B87', '#8DA7BE', '#BCCCDC'], // Customize the colors
  };
  const [series, setSeries] = useState([
    {
      name: 'Composting',
      data:
        selectedOption === 'Location'
          ? [400, 430, 448, 470] // Example data for Locations when 'Location' is selected
          : [1200, 900, 600, 300],  // Example data for Quarters when 'Time' is selected
    },
    {
      name: 'Recycling',
      data:
        selectedOption === 'Location'
          ? [300, 350, 400, 380] // Example data for Locations when 'Location' is selected
          : [1000, 800, 500, 200],  // Example data for Quarters when 'Time' is selected
    },
    {
      name: 'Incinerations',
      data:
        selectedOption === 'Location'
          ? [200, 220, 270, 250] // Example data for Locations when 'Location' is selected
          : [800, 600, 400, 100],  // Example data for Quarters when 'Time' is selected
    },
    {
      name: 'Landfill',
      data:
        selectedOption === 'Location'
          ? [100, 150, 200, 150] // Example data for Locations when 'Location' is selected
          : [600, 400, 300, 100],  // Example data for Quarters when 'Time' is selected
    },
  ]);



  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setView(event.target.value)
    setSelectedCategory(event.target.value === "time" ? quarters[0] : locations[0]); // Reset category selection on option change
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelection(event.target.value)
  };

  function convertMixedData(mixedArray) {
    return mixedArray.map((data) => {
      if (Array.isArray(data.answer) && Array.isArray(data.answer[0])) {
        const flattenedAnswer = data.answer.flat();
        const summedValue = flattenedAnswer.reduce(
          (sum, value) => sum + (parseFloat(value) || 0),
          0
        );

        return {
          questionId: data.questionId,
          sourceId: data.sourceId,
          answer: {
            process: 1, 
            readingValue: summedValue.toString(), 
            unit: "KG",
          },
          title: data.title,
          question_details: data.question_details,
          formDate: data.formDate,
          toDate: data.toDate,
        };
      } else {
        return {
          ...data,
          answer: {
            ...data.answer,
            readingValue: data?.answer?.readingValue || "0",
          },
        };
      }
    });
  }
  useEffect(() => {
    const dataType = view === "time" ? data.time[selection] : data.location[selection];  
    const waterTypes = dataType ? Object.keys(dataType) : [];  
    const newChartSeries = waterTypes.map((waterType) => ({
      name: waterType
        .split(/(?=[A-Z])/)
        .join(" ")
        .replace(/\b\w/g, (l) => l.toUpperCase()), 
      data: dataType?.[waterType] || [],
    }));
    const colors = waterTypes.map(
      (_, index) => colorPalette[index % colorPalette.length]
    );
    setColors(colors);
  
    setSeries(newChartSeries);
    setChartOptions((prevState) => {
      return {
        ...prevState,
        xaxis: {
          categories: view === "time" ? quarters : locations,
          type: "category",
          labels: {
            style: {
              fontSize: "8px", // Increase font size if needed
              colors: "#000000", // Set to black or another visible color
            },
            trim: false, // Avoid trimming long labels
          },
        },
        colors: colors,
      };
    });
  }, [view, selection, data]);

  useEffect(() => {
    const valuesArray = locationOption
      ? locationOption.map((item) => item.unitCode || item.value)
      : [];
if(timePeriods){
    const transformedKeys = Object.keys(timePeriods).map((key) =>
      key.toUpperCase()
    );

     setSelection(view === "time" ? valuesArray[0] : transformedKeys[0]);
     setSelectedCategory(view === "time" ? valuesArray[0] : transformedKeys[0])
    setQuarters(transformedKeys);
    setLocations(valuesArray);


    const summary = {
      time: {},
      location: {},
    };

    locationOption.forEach((location) => {
      transformedKeys.forEach((quarter) => {
        summary.location[quarter] = {
          "Emissions due to GRID electricity":
            new Array(locationOption.length).fill(0), 
          "Emissions due to Electricity Power plant (Captive Power Plant - Natural Gas)": new Array(
            locationOption.length
          ).fill(0),
         
        };
      });
    });

    transformedKeys.forEach((quarter) => {
      locationOption.forEach((location) => {
        summary.time[location.unitCode] = {
          "Emissions due to GRID electricity":
          new Array(transformedKeys.length).fill(0), 
        "Emissions due to Electricity Power plant (Captive Power Plant - Natural Gas)": new Array(
          transformedKeys.length
        ).fill(0),
         
        };
      });
    });

    const filteredData = graphData.filter(
      (item) =>
        item.questionId === 425 ||
        item.questionId === 427 
    );
    const convertedData = convertMixedData(filteredData);
    const timeKey = [];
    const locationKey = [];

    for (const period in summary.location) {
      timeKey.push(period);
    }

    for (const period in summary.time) {
      locationKey.push(period);
    }

    for (const location in summary.time) {
      const data = summary.time[location];
      for (const key in data) {
        // Log the key
        console.log(key);
        for (let k = 0; k < summary.time[location][key].length; k++) {
          let time = timeKey[k];
          const obj = locationOption.find((item) => item.unitCode === location);
          const lowerCaseKey = time;
          const formDate = timePeriods[lowerCaseKey];
          const filterData = convertedData.find(
            (item) =>
              item.title === key &&
              item.formDate === formDate &&
              item.sourceId === obj.id
          );
          summary.time[location][key][k] = Number(filterData?.answer?.readingValue)||0;
        }
      }
      console.log(summary.time[location], "jhhjiuuiiuiui");
    }
    for (const time in summary.location) {
      const data = summary.location[time];
      for (const key in data) {
        // Log the key
        console.log(key);
        for (let k = 0; k < summary.location[time][key].length; k++) {
          let location = locationKey[k];
          const obj = locationOption.find((item) => item.unitCode === location);
          const lowerCaseKey = time;
          const formDate = timePeriods[lowerCaseKey];
          const filterData = convertedData.find(
            (item) =>
              item.title === key &&
              item.formDate === formDate &&
              item.sourceId === obj.id
          );
          summary.location[time][key][k] = Number(filterData?.answer?.readingValue)||0;
        }
      }
    }
 
    setData({
      time: summary.time,
      location: summary.location,
    });}
  }, [locationOption, timePeriods, graphData]);


  return (
    <div className='container' style={{height:"100%"}}>
      <div>
      <div className="header">
          <div className="title">Product Wise Emission Scope 2</div>
        </div>
        <div style={{marginLeft:"1%",marginTop:"1.5%"}}>
        <label>
          <input
            type="radio"
            value="time"
            checked={selectedOption === 'time'}
            onChange={handleOptionChange}
          />
          Time
        </label>
        <label>
          <input
            type="radio"
            value="location"
            checked={selectedOption === 'location'}
            onChange={handleOptionChange}
          />
          Location
        </label>

        </div>
        
      </div>
      <div style={{marginLeft:"1%",marginTop:"1.5%"}}>
      {categories.map((category) => (
          <label key={category}>
            <input
              type="radio"
              value={category}
              checked={selectedCategory === category}
              onChange={handleCategoryChange}
            />
            {category}
          </label>
        ))}
      </div>
      <div id="chart" style={{height:'70%'}}>
        <ReactApexChart options={chartOptions} series={series} type="bar" height={"80%"} />
      </div>
      <div className="legend-container" style={{ width:"30vw", marginTop:"-2%",overflow:"auto"}}>
              {chartOptions.colors &&
                chartOptions.colors.length > 0 &&
                chartOptions.colors.map((color, index) => (
                  <div className="legend-item" style={{width:"10vw"}}key={index}>
                    <div
                      className="legend-color-box"
                      style={{ backgroundColor: color }}
                    ></div>
                    <span className="legend-text">
                    {series[index]?.name.replace("G H G Emissions Due To Consumption Of", "")}
                    </span>
                  </div>
                ))}
            </div>

    </div>
  );
};

export default ScopeMultiLocComparison;
