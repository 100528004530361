import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';  // Import Tooltip and OverlayTrigger

// Import Bootstrap CSS for styling
import 'bootstrap/dist/css/bootstrap.min.css';

const TotalEmissionGeneratedSingle = ({ consumption, notGenerated }) => {
  // Calculate the width of the filled portion of the bar based on consumption
  const filledWidth = (1 / 1) * 100;
  let totalEnergy = consumption + notGenerated;

  // Define the tooltip content
  const renderTooltip = (props) => (
    <Tooltip id="energy-bar-tooltip" {...props}>
      {`Total Emission: ${totalEnergy.toFixed(2)} GJ`}
    </Tooltip>
  );

  return (
    <div className="energy-bar-container">
      <div className="energy-bar-header">
        Total Emission Generated
      </div>
      <div className="energy-bar-labels">
        <span>{0}</span>
        <span>{((1 / 5) * totalEnergy).toFixed(2)}</span>
        <span>{((2 / 5) * totalEnergy).toFixed(2)}</span>
        <span>{((3 / 5) * totalEnergy).toFixed(2)}</span>
        <span>{((4 / 5) * totalEnergy).toFixed(2)}</span>
        <span>{((5 / 5) * totalEnergy).toFixed(2)}</span>
      </div>
      <div className="energy-bar-dotted-line"></div>

      <OverlayTrigger
        placement="top"
        overlay={renderTooltip}
      >
        <div className="energy-bar">
          <div 
            className="energy-bar-filled" 
            style={{ width: `${filledWidth}%`, display:"flex", justifyContent:"center",alignItems:"center", color:"white" }}
   
          >
            {totalEnergy}
          </div>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default TotalEmissionGeneratedSingle;
