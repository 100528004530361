import React from "react";
import { useEffect, useState } from "react";
import edit from "./edit.png";


import { apiCall } from "../../_services/apiCall";
import config from "../../config/config";
import { Modal, Dropdown, Button } from "react-bootstrap";
const QuestionFrequency = () => {
  const [show, setShow] = useState(false);
  const [id, setId] = useState({});
  const [frequency, setFrequency] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSave = async () => {
    const financialYearId = await getFinancialYear();

    const { isSuccess, data, error } = await apiCall(
      config.POSTLOGIN_API_URL_COMPANY + `saveFrequencyModule`,
      {},
      {
        financialYearId,
        moduleId: id,
        frequency: frequency,
      },
      "POST"
    );
    if (isSuccess) {
      handleClose();
      getReportingQuestions();
    }
  };

  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess) {
     return data.data[data?.data?.length - 1].id
    }
  };

  const editItem = (id) => {
    setId(id);
    handleShow();
  };
  const handleSelect = (selectedKey) => {
    setFrequency(selectedKey);
  };

  const [data, setData] = useState([]);

  const fetchFrameworkApi = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFramework`,
      {},
      { type: "ALL" }
    );
    if (isSuccess) { 
      return data?.data.map((item) => item.id)  ;
    }
  };
  const getReportingQuestions = async () => {
    const financialYearId = await getFinancialYear();
    try {
      const response = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getFrequencyModule`,
        {},
        { frameworkIds: await fetchFrameworkApi(),financialYearId},
        "GET"
      );
      if (response.isSuccess) {
        const data = response.data;
        setData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getReportingQuestions();
  }, []);

  return (
    <div  className="Introduction framwork_2" style={{width:"100%", borderRadius:"15px", background:"white", padding:"2rem"}}>
     <table className="table">
      <thead style={{ border: "none" }}>
        <tr   className="fixed_tr_section"
                    style={{
                      border: "none",
                      borderBottom: "2px solid #83BBD5",
                    }}>
          <th   style={{
                        border: "none",
                        color: "#11546F",
                        fontSize: "18px",
                        fontWeight: 600,
                      }}>Title</th>
          <th   style={{
                        border: "none",
                        color: "#11546F",
                        fontSize: "18px",
                        fontWeight: 600,
                      }}>Frequency</th>
          <th   style={{
                        border: "none",
                        color: "#11546F",
                        fontSize: "18px",
                        fontWeight: 600,
                      }}>Actions</th>
        </tr>
      </thead>
      </table>
      <div style={{ width: "100%" }}>
        {/* Map over the data array and render a table row for each item */}
        {data?.data?.map((item) => (
          <div key={item?.moduleId }   style={{ display: "flex", width: "100%", padding:"20px 0px", borderBottom:"1px solid #3f88a5"  }}>
            <div  style={{
                                width: "23%",
                                color: "#3f8aa5",
                                fontSize: "16px",
                              }}>{item?.moduleName}</div>
            <div  style={{
                                width: "44%",
                                color: "#3f8aa5",
                                fontSize: "16px",
                                textTransform:"capitalize"
                              }}>
              {typeof item?.frequencies === "string"
                ? item.frequencies.replace(/_/g, " ").toLowerCase()
                : item?.frequencies}
            </div>
            <div  style={{
                                width: "25%",
                                color: "#3f8aa5",
                                fontSize: "16px",
                              }}>
              <img
              src={edit}
                
                onClick={() => editItem(item?.moduleId)}
              ></img>
            </div>
          </div>
        ))}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Frequency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle
              id="dropdown-basic"
              style={{ backgroundColor: "transparent", color: "black" }}
            >
              {frequency || "Frequency"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="MONTHLY">Monthly</Dropdown.Item>
              <Dropdown.Item eventKey="QUARTERLY">Quarterly</Dropdown.Item>
              <Dropdown.Item eventKey="HALF_YEARLY">Half Yearly</Dropdown.Item>
              <Dropdown.Item eventKey="YEARLY">Yearly</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
   
    </div>
   
  );
};

export default QuestionFrequency;
